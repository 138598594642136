import { FC, useCallback } from 'react';

import { useScrollStore, useStickyState } from '@marriott/mi-store-utils';

import { StyledStickyComponentWrapper } from './StickyComponentWrapper.styles';
import { StickyComponentWrapperProps } from './StickyComponentWrapper.types';

export const StickyComponentWrapper: FC<StickyComponentWrapperProps> = props => {
  const { model, children } = props;

  const setRegisteredComponent = useScrollStore(state => state.setRegisteredComponent);
  const { top, isSticky, offset } = useStickyState(model.componentId);

  const refCallback = useCallback((ref: HTMLDivElement, topOffset: number = 0) => {
    if (!ref) {
      return;
    }

    setRegisteredComponent({
      componentId: model.componentId,
      isStickyOnScrollUp: !!model?.scrollSetting?.isStickyOnScrollUp,
      isStickyOnScrollDown: !!model?.scrollSetting?.isStickyOnScrollDown,
      height: ref.clientHeight,
      topOffset,
    });
  }, []);

  return (
    <StyledStickyComponentWrapper
      $isSticky={isSticky}
      $top={top}
      $offset={offset}
      data-testid="sticky-component-wrapper"
    >
      {children(isSticky, refCallback)}
    </StyledStickyComponentWrapper>
  );
};
