/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  monthsArray,
  METERSTOMILES,
  METERSTOKM,
  ROOM_THRESHOLD_1,
  ROOM_THRESHOLD_2,
  RANGE_4_9,
  RANGE_10_25,
  RANGE_26_PLUS,
  MRW_CODE,
  P17_CODE,
  RATE_UNIT,
  RATE_MODE,
  POINTS,
  RateNodeTypeToBeUsed,
  DTT_HEADERS,
  DTT_UXL_HEADERS,
  DTT_HEADERS_TYPE,
  constants,
  errorMessageCodes,
} from '../../constants/lib/constants';
import {
  SPECIAL_CODE,
  STATUS_MAPPER,
  RATE_CATEGORY_MAPPER,
} from '../../lib/organisms/SearchResults/component/index.constant';
import {
  DTTStatusBlockProps,
  SearchRateAmountType,
  SearchRateAmountTypeDTT,
  SearchRateType,
} from '../../lib/organisms/SearchResults/store/store.type';
import { ES_LOCALE, PT_BR_LOCALE, RU_LOCALE, TH_LOCALE } from '../../modules/constants';
import { AEMAlertBodyProps, AlertMessageBodyProps } from '../../organisms/SearchForm/SearchForm.types';
import { capitalize, logger } from '../index';
import {
  AVERAGE_NIGHTLY_RATE_PER_UNIT,
  POINTS_PER_UNIT,
  SUBTOTAL_PER_UNIT,
  ADF_PRODUCT_STATUS_TYPE,
  ADF_IS_FLEXIBLE_PAYLOAD,
} from '../../constants/Calendar.constant';

/**
 * returns the boolean true/false.
 */
const canUseDOM = !!(typeof window !== 'undefined' && window.document);

export function isDateAfterToday(dateString: string): boolean {
  const year = +dateString.substring(0, 4);
  const month = +dateString.substring(5, 7);
  const day = +dateString.substring(8, 10);
  const date = new Date(year, month - 1, day);
  return new Date(date.toDateString()) > new Date(new Date().toDateString());
}

export function getRoomRange(numofRooms: string, roomsList?: { roomQuantity: string }[]): string {
  const numRooms = parseInt(numofRooms);
  const rangeNum = roomsList?.find(item => parseInt(item?.roomQuantity || '1') === parseInt(numofRooms))?.roomQuantity;

  if (numRooms < ROOM_THRESHOLD_1) {
    return numRooms.toString();
  } else if (numRooms < ROOM_THRESHOLD_2) {
    return rangeNum || RANGE_4_9;
  } else if (numRooms <= 25) {
    return rangeNum || RANGE_10_25;
  } else {
    return rangeNum || RANGE_26_PLUS;
  }
}

/*
To convert phone number format for DE & ZH domain
*/
const convertPhoneToDEAndZH = (phoneNumber: string): string => {
  let parts;
  const index = phoneNumber?.indexOf(' ');
  if (index === -1) {
    parts = [phoneNumber, ''];
  }
  parts = [phoneNumber?.slice(0, index), phoneNumber?.slice(index + 1)];

  return parts
    ? parts[0] +
        ' ' +
        parts[1]
          .replaceAll(' ', '')
          .replaceAll('-', '')
          .replace(/(\d{3})(\d{4})/, '$1 $2')
    : '';
};

/*
To format phone number format for DE domain , ZH Domain and US domain
*/ export const formatPhoneNumber = (phoneNumberString: string, lang: string): string => {
  const { log } = logger({})('formatPhoneNumber');
  log.debug(
    `[PerfTesting][SMHotelGalleryTitle] lang:${lang} Entering the formatPhoneNumber function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
  );
  let returnedNumber = '';
  switch (lang) {
    case 'de-DE':
    case 'zh-TW':
      log.debug(
        `[PerfTesting][SMHotelGalleryTitle] lang:${lang} Exiting the formatPhoneNumber function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
      );
      returnedNumber = convertPhoneToDEAndZH(phoneNumberString);
      return returnedNumber;
    default:
      if (phoneNumberString?.includes('+')) {
        if (phoneNumberString.replaceAll(' ', '').replaceAll('-', '')?.split('')?.length > 12) {
          returnedNumber = phoneNumberString
            .replaceAll(' ', '')
            .replaceAll('-', '')
            .replace(/(\d{2})(\d{3})(\d{4})/, '$1 $2 $3');
        } else {
          returnedNumber = phoneNumberString
            .replaceAll(' ', '')
            .replaceAll('-', '')
            .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 $2-$3-$4');
        }
      }
      log.debug(
        `[PerfTesting][SMHotelGalleryTitle] lang:${lang} Exiting the formatPhoneNumber function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
      );
      return returnedNumber;
  }
};

export const formatNumberByLocale = (numb: number | string, lang: string): string => {
  const { log } = logger({})('formatNumberByLocale');
  log.debug(
    `[PerfTesting][utils] Entering the formatNumberByLocale lang:${lang} function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
  );
  const inputStr = numb ?? '';
  // convert input number to locale string according to the lang
  // const str = Number(inputStr).toLocaleString(lang || '');
  let str = '';
  switch (lang) {
    case 'ko-KR':
    case 'ja-JP':
    case 'it-IT':
    case 'fr-FR':
    case 'de-DE':
    case 'ru-RU':
      str = Number(inputStr)?.toLocaleString(lang || '', { maximumFractionDigits: 2 });
      break;
    case 'pt-BR':
      str = Number(inputStr)?.toLocaleString(lang || '', { maximumFractionDigits: 3 });
      break;
    case 'es-ES':
      str = Number(inputStr)?.toLocaleString(lang || '', { maximumFractionDigits: 2, useGrouping: true });
      break;
    case '':
    case undefined:
      str = Number(inputStr)?.toLocaleString();
      break;
    default:
      str = Number(inputStr)?.toLocaleString(lang || '');
      break;
  }
  log.debug(
    `[PerfTesting][utils] Exiting the formatNumberByLocale lang:${lang} function at time: ${new Date().getHours()} ${new Date().getMinutes()} ${new Date().getSeconds()} ${new Date().getMilliseconds()}`
  );
  return str;
};

/**
 * Function creates compact format for a given number based on the locale
 *
 * @param numb | string | undefined
 * @param locale | string
 * @param compactFormatLiteral | any
 * @returns string
 */
export function getCompactFormat(numb: number | string | undefined, locale: string, compactFormatLiteral: any): string {
  if (!numb) return '';
  if (Number(numb) < 1000) {
    return numb?.toString();
  }

  const numberLength = Math.trunc(Number(numb)).toString().length;
  const isMillion = !(numberLength > 3 && numberLength < 7);
  let result = '';
  switch (locale) {
    case 'ko-KR':
    case 'ja-JP':
      result = isMillion
        ? Number(Number(numb) / 1000000).toLocaleString(locale, { maximumFractionDigits: 1 }) +
          compactFormatLiteral?.millionLabel
        : Number(numb).toLocaleString(locale, { maximumFractionDigits: 1 });
      break;
    case 'en-US':
      result =
        Number(Number(numb) / (isMillion ? 1000000 : 1000)).toLocaleString(locale, { maximumFractionDigits: 1 }) +
        (isMillion ? compactFormatLiteral?.millionLabel : compactFormatLiteral?.thousandLabel);
      break;
    case 'it-IT':
    case 'fr-FR':
    case 'de-DE':
    case 'pt-BR':
    case 'ru-RU':
      result =
        Number(Number(numb) / (isMillion ? 1000000 : 1000)).toLocaleString(locale, { maximumFractionDigits: 1 }) +
        ' ' +
        (isMillion ? compactFormatLiteral?.millionLabel : compactFormatLiteral?.thousandLabel);
      break;
    case 'es-ES':
      result =
        Number(Number(numb) / (isMillion ? 1000000 : 1000)).toLocaleString(locale, {
          maximumFractionDigits: 1,
          useGrouping: true,
        }) +
        ' ' +
        (isMillion ? compactFormatLiteral?.millionLabel : compactFormatLiteral?.thousandLabel);
      break;
    default:
      result = numb?.toString();
      break;
  }
  return result;
}

// TODO: Please add code comment explaining what this function does.
export function dateReturner(dateString: string): string {
  const monthIndex = (+dateString.substring(5, 7) - 1).toString();
  const year = +dateString.substring(0, 4);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const monthMapping: any = monthsArray;
  const displayedMonth = monthMapping?.[monthIndex]?.['monthLabel'] ?? '';
  return `${displayedMonth} ${year}`;
}

export function getCalculatedDistance(dist: number | undefined, km: boolean, lang?: string): string | null {
  /** calculated distance for km/ miles */
  if (dist) {
    if (km) {
      // convert meters to km
      return formatNumberByLocale((dist / METERSTOKM).toFixed(1), lang || '');
    } else {
      // convert meters to miles
      return formatNumberByLocale((dist / METERSTOMILES).toFixed(1), lang || '');
    }
  }
  return null;
}

// callback function that will be used by the replace function in getFormattedErrorKey
// used to remove full stops and convert to camel case
function replacer(match: string): string {
  return match?.[1]?.toUpperCase();
}

// function that accepts dot separated string and returns camel cased key
function getFormattedErrorKey(input: string) {
  return input?.toLowerCase()?.replace(/\../g, replacer);
}

export const returnApplicableAlert = (matchKey: string, alertBody: AEMAlertBodyProps[]) => {
  const returnedAlert: any = {};
  const shortlistedAlert =
    alertBody &&
    alertBody?.filter((alert: any) => alert?.alertMsgTypeName?.toLowerCase()?.trim() === matchKey?.toLowerCase())?.[0];
  returnedAlert['alertMessage'] = shortlistedAlert
    ? shortlistedAlert?.alertCodeListModel[0]?.['alertMessageLabel']
    : '';
  returnedAlert['alertCode'] = shortlistedAlert ? shortlistedAlert?.alertMsgTypeName : '';
  returnedAlert['alertType'] = shortlistedAlert ? shortlistedAlert?.alertMsgType : '';
  returnedAlert['linkText'] = shortlistedAlert?.alertCodeListModel?.[0]?.alertLinkText ?? '';
  returnedAlert['messageRedirection'] = shortlistedAlert?.alertCodeListModel?.[0]?.alertMessageRedirection ?? '';
  returnedAlert['messageLink'] = shortlistedAlert?.alertCodeListModel?.[0]?.alertMessageLink ?? '';
  returnedAlert['alertLinkTextLabel'] = shortlistedAlert?.alertCodeListModel?.[0]?.alertLinkTextLabel ?? '';
  returnedAlert['stringNeedsToBeSplit'] = shortlistedAlert
    ? shortlistedAlert?.alertCodeListModel[0]?.['stringNeedsToBeSplit']
    : '';
  return returnedAlert;
};

export function displaySessionBasedAlerts(alertBody: AEMAlertBodyProps[], errorMessageKey: any) {
  let applicableAlert: AlertMessageBodyProps = {};
  let errorKey = '';
  if (errorMessageKey) {
    const sessionErrorKey = errorMessageKey;
    const splitErrorMessages = ('|' + sessionErrorKey)?.split('|');
    errorKey = getFormattedErrorKey(splitErrorMessages[splitErrorMessages?.length - 1] ?? '');
  }
  if (errorKey !== '' || errorKey !== undefined) {
    applicableAlert = returnApplicableAlert(errorKey, alertBody);
    if (applicableAlert && applicableAlert?.alertCode) {
      return applicableAlert;
    }
  }
  return {};
}

export function convertDateToTextFormat(inputDate: string, monthsArray: string[]): string {
  const dateSegments = inputDate.split('-');
  const month = Number(dateSegments[0]);
  const outputDate = monthsArray[month - 1] + ' ' + dateSegments[1] + ' ' + dateSegments[2];
  return outputDate;
}

//accepts rates in bauFormat and returns in DTT format
export const dttRatesTransformer = (rates?: Array<SearchRateType>) => {
  const ratesInDTTFormat = rates?.map((rate: SearchRateType) => {
    //looping though different rate types like stndardrates, points, special rates
    const tempDTTFormatRatesBlock = { ...rate };
    const bauRateCategory = rate?.rateCategory?.type?.code as keyof typeof RATE_CATEGORY_MAPPER;
    tempDTTFormatRatesBlock.rateCategory = {
      //rateCategory format in DTT response
      code: RATE_CATEGORY_MAPPER[bauRateCategory],
      value: rate?.rateCategory?.value,
    };
    const statusCode = rate?.status?.code as keyof typeof STATUS_MAPPER;
    tempDTTFormatRatesBlock.status = {
      //status format in DTT response
      code: STATUS_MAPPER[statusCode],
    };
    //for points and points saver, pointsPerUnit field is required in new DTT format
    if (
      (rate?.rateCategory?.type?.code === SPECIAL_CODE && rate?.rateCategory?.value === MRW_CODE) ||
      (rate?.rateCategory?.type?.code === SPECIAL_CODE && rate?.rateCategory?.value === P17_CODE)
    ) {
      const bauPointsBlock: SearchRateAmountType | undefined = rate?.rateAmounts?.find(
        (rateAmount: SearchRateAmountType) => rateAmount?.rateMode?.code === POINTS_PER_UNIT
      );

      tempDTTFormatRatesBlock.rateModes = {
        pointsPerUnit: {
          //for points and points saver, pointsPerUnit field is required in new DTT format
          points: bauPointsBlock?.points as number,
        },
      };
    }
    //for price, lowestAverageRate field is required in new DTT format
    else {
      const rateModes = { lowestAverageRate: {} }; //for price, lowestAverageRate field is required in new DTT format
      const bauRatesBlock: SearchRateAmountType = rate?.rateAmounts?.find(
        (rateAmount: SearchRateAmountType) => rateAmount?.rateMode?.code === RateNodeTypeToBeUsed
      ) as SearchRateAmountType;

      for (const key in bauRatesBlock) {
        //loop through the cash rate block and convert it to new DTT block
        if (key === RATE_UNIT || key === RATE_MODE || key === POINTS) {
          //These keys are not required in new DTT response
          delete bauRatesBlock[key];
        } else {
          if (bauRatesBlock[key as keyof SearchRateAmountTypeDTT] !== null) {
            // out of the keys fees, taxes, totalAmount, amountPlusMandatioryFees update only available fields
            bauRatesBlock[key as keyof SearchRateAmountTypeDTT] = {
              amount: bauRatesBlock[key as keyof SearchRateAmountTypeDTT]?.origin?.amount,
              currency: bauRatesBlock[key as keyof SearchRateAmountTypeDTT]?.origin?.currency,
              valueDecimalPoint: bauRatesBlock[key as keyof SearchRateAmountTypeDTT]?.origin?.valueDecimalPoint,
            };
          }
        }
      }

      rateModes.lowestAverageRate = { ...bauRatesBlock };
      tempDTTFormatRatesBlock.rateModes = rateModes;
    }
    delete tempDTTFormatRatesBlock.rateAmounts;
    return tempDTTFormatRatesBlock;
  });
  return ratesInDTTFormat;
};

//accepts descriptions in bauFormat and returns in DTT format
export const dttDescriptionTransformer = (descriptions: any) => {
  const descriptionInDTTFormat = descriptions.map(
    (description: { text: string; localizedText?: { translatedText: string } }) => {
      const tempDTTFormatDescriptionBlock = { ...description };
      tempDTTFormatDescriptionBlock.text =
        tempDTTFormatDescriptionBlock?.localizedText?.translatedText ?? tempDTTFormatDescriptionBlock?.text;
      delete tempDTTFormatDescriptionBlock.localizedText;
      return tempDTTFormatDescriptionBlock;
    }
  );
  return descriptionInDTTFormat;
};

//accepts error object in bauFormat and returns in DTT format
// revamped to convert BAU to AMP format.
export const dttErrorsTransformer = (statusObject: any) => {
  const tempStatusObj = [...statusObject];
  const messagesBlock =
    statusObject?.[0]?.messages?.[0]?.user ||
    statusObject?.[0]?.messages?.[0]?.dev ||
    statusObject?.[0]?.messages?.[0]?.ops;
  tempStatusObj[0].status = [];
  if (errorMessageCodes.includes(messagesBlock?.field)) {
    tempStatusObj[0].errors = [
      {
        code: messagesBlock?.field,
        devMessage: messagesBlock?.message,
        message: messagesBlock?.message,
      },
    ];
  } else {
    tempStatusObj[0].warnings = [
      {
        code: messagesBlock?.field,
        devMessage: messagesBlock?.message,
        message: messagesBlock?.message,
      },
    ];
  }
  delete tempStatusObj[0].messages;
  return tempStatusObj;
};

export const getDTTHeaders = (headersData: any, query: any) => {
  let mgpHeaders: DTT_HEADERS_TYPE = {};
  const isDTT = headersData?.['dtt'] === 'true' || query?.['dtt'] === 'true';
  const isUXLDTT = headersData?.['uxl-dtt'] === 'true' || query?.['uxl-dtt'] === 'true';
  if (isDTT) {
    mgpHeaders = isUXLDTT ? DTT_UXL_HEADERS : DTT_HEADERS;
  }
  return mgpHeaders;
};

export function convertToLowerCase(text: string, locale: string) {
  if (locale === PT_BR_LOCALE) {
    return text?.toLocaleLowerCase();
  } else {
    return text;
  }
}
export function convertToUpperCase(text: string, locale: string) {
  switch (locale) {
    case RU_LOCALE:
      return text?.toLocaleUpperCase();
    case ES_LOCALE:
      return capitalize(text);
    default:
      return text;
  }
}

export function tranformADFEdgeToDTT(bauADFEdge: any) {
  if (!bauADFEdge) {
    return;
  }
  const newNode = { ...bauADFEdge.node };
  newNode.startDate = bauADFEdge?.node?.basicInformation?.startDate;
  newNode.endDate = bauADFEdge?.node?.basicInformation?.endDate;
  newNode.rateModes = {
    lowestAverageRate: {},
    totalRate: {},
  };

  //Read Average nightly rate from bau response and add it to lowestAverageRate attribute
  const lowestRate = bauADFEdge.node.rates.rateAmounts.filter((rateAmount: { rateMode: { code: string } }) => {
    return rateAmount.rateMode.code === AVERAGE_NIGHTLY_RATE_PER_UNIT;
  });
  newNode.rateModes.lowestAverageRate.amount = {
    amount: lowestRate?.[0]?.amount?.origin?.amount,
    currency: lowestRate?.[0]?.amount?.origin?.currency,
    valueDecimalPoint: lowestRate?.[0]?.amount?.origin?.valueDecimalPoint,
  };

  //Read sub-total-per-unit from bau response and add it to lowestAverageRate attribute
  const totalNewRate = bauADFEdge.node.rates.rateAmounts.filter((rateAmount: { rateMode: { code: string } }) => {
    return rateAmount.rateMode.code === SUBTOTAL_PER_UNIT;
  });
  newNode.rateModes.totalRate.amount = {
    amount: totalNewRate?.[0]?.amount?.origin?.amount,
    currency: totalNewRate?.[0]?.amount?.origin?.currency,
    valueDecimalPoint: totalNewRate?.[0]?.amount?.origin?.valueDecimalPoint,
  };

  //Points
  newNode.rateModes.pointsPerQuantity = bauADFEdge?.node?.totalPricing?.rateAmountsByMode?.pointsPerQuantity;

  delete newNode.basicInformation;
  delete newNode.totalPricing;
  delete newNode.rates;
  return { node: newNode };
}

export function transformToBauADFPayload(dttADFPayload: any) {
  const bauAdfPayload = { ...dttADFPayload };
  const quantity = dttADFPayload?.search?.options.numberOfRooms;
  bauAdfPayload.search.options.quantity = quantity;
  bauAdfPayload.search.options.productStatusType = ADF_PRODUCT_STATUS_TYPE;
  bauAdfPayload.search.options.flexibleDates = ADF_IS_FLEXIBLE_PAYLOAD;
  delete bauAdfPayload.search.options.numberOfRooms;
  return bauAdfPayload;
}

//Function to get the proper destination type according to the presedence
export function getSearchDestinationTypeAnalytics(destTypes: string[]) {
  const preDefinedOrder = [
    { value: 'street_address', order: 1, label: 'Address' },
    { value: 'subpremise', order: 1, label: 'Address' },
    { value: 'point_of_interest', order: 2, label: 'Point of Interest' },
    { value: 'airport', order: 3, label: 'Airport' },
    { value: 'lodging', order: 4, label: 'Hotel Name' },
    { value: 'neighborhood', order: 5, label: 'Neighborhood' },
    { value: 'sublocality_level_1', order: 6, label: 'Borough' },
    { value: 'administrative_area_level_3', order: 6, label: 'Borough' },
    { value: 'locality', order: 7, label: 'City' },
    { value: 'administrative_area_level_2', order: 8, label: 'County' },
    { value: 'administrative_area_level_1', order: 9, label: 'State/Province/Region' },
    { value: 'colloquial_area', order: 10, label: 'Leisure Region' },
    { value: 'country', order: 11, label: 'Country' },
  ];
  for (let i = 0; i < destTypes?.length; i++) {
    const matchedItem = preDefinedOrder?.find(item => item.value === destTypes[i]);
    if (matchedItem) {
      return matchedItem?.label;
    }
  }
  return 'NA';
}

/**
 * Retrieves the user's country code from Akamai's browser location data if available.
 * Returns the default country code if the data is not available or the DOM is not accessible.
 *
 * @returns {string} - The user's country code (in uppercase) or the default country code.
 */
export function getUserCountryCodeFromAkamai(): string {
  if (!canUseDOM) {
    return constants.DEFAULT_COUNTRY.toUpperCase();
  }

  const countryCode =
    (window?.dataLayer?.['browser_akamai_loc_country'] as unknown as string) ?? constants.DEFAULT_COUNTRY;
  return countryCode.toUpperCase();
}

export const checkLocaleToAddYears = (currentLocale: string, year: number): number => {
  switch (currentLocale) {
    case TH_LOCALE:
      return Number(year + 543);
    default:
      return year;
  }
};

export const modifyDateStringForThai = (dateString: string): string => {
  let modifiedDateString = '';
  let i = 0;

  while (i < dateString.length) {
    // Check if the current character is a numeric digit
    if (dateString[i] >= '0' && dateString[i] <= '9') {
      // Found the start of a sequence of numeric digits
      const start = i;
      const end = i + 3;

      // Ensure we have at least 4 characters left in the string
      if (end < dateString.length && dateString[end] >= '0' && dateString[end] <= '9') {
        // Extract the 4 consecutive numeric digits
        const numericDigits = dateString.slice(start, end + 1);

        // Convert the numeric string to a number, add the given value
        const newValue = parseInt(numericDigits) + 543;

        // Append the modified numeric value to the result string
        modifiedDateString += newValue.toString();

        // Move the index past the 4 consecutive numeric digits
        i = end + 1;
      } else {
        // If not enough characters left, just append the current character
        modifiedDateString += dateString[i];
        i++;
      }
    } else {
      // Append non-numeric characters directly to the result string
      modifiedDateString += dateString[i];
      i++;
    }
  }

  return modifiedDateString;
};

// Function to apply text transformations to room text
export const transformRoomText = (roomText: string | undefined, currentLocale: string) => {
  let transformedRoomText = roomText;
  switch (currentLocale) {
    case 'es-ES':
      transformedRoomText = roomText?.toLowerCase();
      break;
  }
  return transformedRoomText;
};

// Function to apply text transformations to guest text
export const transformGuestText = (guestText: string | undefined, currentLocale: string) => {
  let transformedGuestText = guestText ? capitalize(guestText) : guestText;
  switch (currentLocale) {
    case 'es-ES':
      transformedGuestText = guestText?.toLowerCase();
      break;
  }
  return transformedGuestText;
};
