import { specialCharsRegex } from './constants';
import type { KeywordBlacklistEntry } from './createKeywordBlacklistChecker.types';

/**
 * Normalizes the blacklisted keywords by extracting, trimming, filtering out invalid entries,
 * converting to lowercase, and removing duplicates.
 *
 * This function takes a `KeywordBlacklistEntry` object (or `undefined`) and performs the following steps:
 * 1. Extracts the `keywords` array from the `blacklist`.
 * 2. Removes all spaces (leading, trailing, and in-between) from each keyword's `value`.
 * 3. Converts the cleaned keyword to lowercase.
 * 4. Removes any duplicate keywords.
 * 5. Filters out any empty or falsy keyword values.
 *
 * @param {KeywordBlacklistEntry | undefined} blacklist - The object containing an optional list of blacklisted keyword entries.
 *
 * @returns {Set<string>} A filtered, cleaned, deduplicated set of blacklisted keyword strings in lowercase.
 *
 * @example
 * const blacklist = { keywords: [{ value: '  Forbidden   Word  ' }, { value: 'prohibited' }, { value: 'FORBIDDENWORD' }] };
 * const result = normalizeBlacklistedKeywords(blacklist);
 * // result: Set { 'forbiddenword', 'prohibited' }
 */
export const normalizeBlacklistedKeywords = (blacklist: KeywordBlacklistEntry | undefined): Set<string> => {
  const keywords = (blacklist?.keywords || [])
    .map(item => item?.value?.replace(/\s+/g, '').toLowerCase() || '')
    .filter(Boolean);

  // Deduplicate the keywords using a Set and return it
  return new Set(keywords);
};

/**
 * Cleans a keyword by removing any special characters defined in the specialCharsRegex
 * and converts the result to lowercase.
 *
 * @param {string} keyword - The keyword to be cleaned.
 * @returns {string} The cleaned keyword with special characters removed and converted to lowercase.
 *
 * @example
 * cleanKeyword('Test@Keyword'); // returns 'testkeyword'
 */
export const cleanKeyword = (keyword: string): string => {
  return keyword.replace(specialCharsRegex, '').toLowerCase();
};
