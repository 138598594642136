import { Button, Types, Image } from '@marriott/mi-ui-library';
import clsx from 'clsx';

import { EditableComponent } from '@adobe/aem-react-editable-components';
import {
  ButtonWrapper,
  PostcardBackground,
  PostcardHolder,
  PostcardContent,
  PostcardSubtitle,
  PostcardTitle,
  StyledImage,
} from './Postcard.styles';
import { PostcardCTA, PostcardProps } from './Postcard.types';

const PostcardComp = (props: PostcardProps) => {
  const { title, descriptionDesktop, descriptionShort, ctaLinkListItems, dynamicMedia } = props;

  return (
    <PostcardHolder className="container border">
      <PostcardBackground className="row">
        <StyledImage data-testid="background">
          <Image
            defaultImageURL={dynamicMedia.assetPath}
            altText={dynamicMedia.altText}
            dynamic={dynamicMedia.dynamic}
            renditions={dynamicMedia.renditions}
            title={dynamicMedia.altText}
            customClass="pc__img"
          />
        </StyledImage>

        <PostcardContent className="col-md-8">
          <PostcardTitle className="t-title-inverse-m" dangerouslySetInnerHTML={{ __html: title }}></PostcardTitle>
          {descriptionShort && (
            <PostcardSubtitle
              className="t-subtitle-xl description-short"
              dangerouslySetInnerHTML={{
                __html: descriptionShort,
              }}
            ></PostcardSubtitle>
          )}
          <PostcardSubtitle
            className="t-subtitle-xl description-desktop"
            dangerouslySetInnerHTML={{
              __html: descriptionDesktop,
            }}
          ></PostcardSubtitle>

          <ButtonWrapper>
            {ctaLinkListItems?.map((postcardCTA: PostcardCTA, index) => {
              return (
                <Button
                  key={index}
                  href={postcardCTA?.ctaLinkURL}
                  type={Types.ButtonTypeVariation.Button}
                  className={clsx('m-button-s m-button-primary-inverse postcard-button mb-3')}
                >
                  {postcardCTA?.ctaLabel}
                </Button>
              );
            })}
          </ButtonWrapper>
        </PostcardContent>
      </PostcardBackground>
    </PostcardHolder>
  );
};

export const Postcard = (props: PostcardProps) => {
  return props.isAuthorMode ? (
    <EditableComponent
      config={{
        emptyLabel: 'postcard',
        isEmpty: () => false,
        resourceType: 'mi-aem-homepage-spa/components/content/postcard',
      }}
      {...props}
    >
      <PostcardComp {...props} />
    </EditableComponent>
  ) : (
    <PostcardComp {...props} />
  );
};
