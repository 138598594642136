import React, { FC } from 'react';
import clsx from 'clsx';

import { DataBlockProps } from './DataBlock.types';
import { StyledDataBlock } from './DataBlock.styles';
import { Button, getLoyaltyTheme, Heading, Icon, RichText } from '@marriott/mi-ui-library';
import { Image } from '../../atoms/Image';
import { ButtonStyle, size, headingType } from '../../utils/enums/enums';

export const DataBlock: FC<DataBlockProps> = ({
  contentAlignment,
  dataBlockVariations,
  assetVariation,
  iconFontPath,
  iconAltText,
  iconSize,
  fileReferenceImage,
  header = '',
  headerTag,
  headingType: headingTypo = headingType.subtitle,
  description,
  ctaLinkText,
  ctaLink,
  ctaType,
  displayRule,
  descriptionNumber,
  numberField,
  openInNewTab = false,
  trackingProperties,
  styleclass,
  customClass,
  imageSize,
  orientation = 'vertical',
}) => {
  const getButtonStyle = (ctaType: string, openInNewTab: boolean): string => {
    switch (ctaType) {
      case 'primaryButton':
        return openInNewTab ? ButtonStyle.PRIMARY_BUTTON_EXTERNAL : ButtonStyle.PRIMARY_BUTTON;
      case 'secondaryButton':
        return openInNewTab ? ButtonStyle.SECONDARY_BUTTON_EXTERNAL : ButtonStyle.SECONDARY_BUTTON;
      case 'tertiaryLink':
        return openInNewTab ? ButtonStyle.TERTIARY_BUTTON_EXTERNAL : ButtonStyle.TERTIARY_BUTTON;
      default:
        return '';
    }
  };
  const dataBlockBtn = () => {
    return (
      ctaLinkText && (
        <Button
          isLink={true}
          href={ctaLink}
          className={`${buttonTypeClasses} ${ctaType !== 'tertiaryLink' ? ' m-button-s' : ''}`}
          target={openInNewTab === true ? '_blank' : ''}
          trackingProperties={trackingProperties}
          ariaLabel={ctaLinkText}
        >
          {ctaLinkText}
        </Button>
      )
    );
  };
  const iconDecorative = styleclass?.includes('coral') ? 'icon-decorative' : '';
  const isHorizontalOrientation = orientation === 'horizontal';
  const logoCleanup = iconFontPath?.replace('brands/portfolio/', '');
  const logoPath = logoCleanup?.includes('brands') ? `brand-logo-${logoCleanup?.replace('brands/', '')}` : logoCleanup;
  const buttonTypeClasses = getButtonStyle(ctaType || '', openInNewTab);
  const isIconBlock = dataBlockVariations === 'icon';
  const isNumberBlock = dataBlockVariations === 'number';
  const isStandardStyle = styleclass?.includes('standard');
  const isLargeIcon = iconSize === 'large';
  const hasOnlyHeaderAndIcon = !description && !ctaLinkText && header && iconFontPath;
  const loyaltyThemeColor = getLoyaltyTheme(styleclass);

  return (
    <StyledDataBlock
      data-component-name="m-ui-library-DataBlock"
      loyaltyTheme={isStandardStyle ? loyaltyThemeColor : ''}
      data-testid="ui-library-DataBlock"
      className={clsx(
        styleclass,
        customClass,
        `${isIconBlock && contentAlignment === 'center' ? 'carblock-center-align' : ''}`
      )}
    >
      <div
        className={clsx(
          `datablock-container`,
          `${isNumberBlock && displayRule === 'vertical' && 'd-flex'}`,
          `${isHorizontalOrientation && 'db-icon-horizontal-orientation'}`
        )}
      >
        {isIconBlock && (
          <>
            {assetVariation === 'iconFont' && (
              <Icon
                iconClass={clsx(
                  iconSize === 'small' ? 'icon-l' : iconSize === 'large' ? 'data-block-large-icon' : '',
                  logoPath,
                  iconDecorative,
                  'data-block-logo-icon'
                )}
                ariaLabel={iconAltText}
              />
            )}
            {assetVariation === 'image' && (
              <div className="data-block-img">
                <Image
                  customClass={imageSize}
                  altText={fileReferenceImage?.altText}
                  renditions={fileReferenceImage?.renditions}
                  dynamic={fileReferenceImage?.dynamic}
                  defaultImageURL={fileReferenceImage?.assetPath}
                />
              </div>
            )}
            <div className="data-block-text-content">
              {header && (
                <Heading
                  element={headerTag}
                  variation={headingTypo}
                  titleText={header}
                  fontSize={size.medium}
                  customClass={clsx(
                    'data-block-heading',
                    isHorizontalOrientation && isLargeIcon && hasOnlyHeaderAndIcon && 'db-header-large-icon-only',
                    isHorizontalOrientation && isLargeIcon && !hasOnlyHeaderAndIcon && 'db-all-details-large-icon'
                  )}
                />
              )}
              {description && (
                <RichText
                  text={description}
                  componentId={'RichText-ImageVariation'}
                  customClass={clsx('t-font-alt-s')}
                />
              )}
              {isHorizontalOrientation && dataBlockBtn()}
            </div>
            {!isHorizontalOrientation && dataBlockBtn()}
          </>
        )}
        {isNumberBlock && (
          <>
            {displayRule === 'horizontal' && (
              <div className={clsx('seperator t-horizontal-vertical-rule seperator-horizontal')}></div>
            )}
            {displayRule === 'vertical' && <div className="seperator t-horizontal-vertical-rule seperator-vertical" />}
            <div className="data-block-number-content">
              {numberField && <span className="t-title-s">{numberField}</span>}
              {descriptionNumber && (
                <RichText
                  text={descriptionNumber}
                  componentId={'RichText-NumberVariation'}
                  customClass={clsx('t-font-alt-s data-block-number-desc')}
                />
              )}
            </div>
          </>
        )}
      </div>
    </StyledDataBlock>
  );
};
