/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import { FC, useEffect, useRef, useState } from 'react';
import moment from 'moment';
/* eslint-disable no-inner-declarations */
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { SearchFormProps } from './SearchForm.types';
import { StyledSearchForm, StyledSearchFormWrapper } from './SearchForm.styles';
import { useSearchFormStore } from '../../modules/store/searchFormStore';
import { getDateObj, getTotalNumberOfDays } from '@marriott/mi-ui-library';
import { SearchFormView } from './SearchForm.controller';
import clsx from 'clsx';
import { StaticDataContextProvider } from '../../modules/context';
import { DATES, HOME_PAGE_HEADER_CONST_HEIGHT } from '../../modules/constants';
import { DESTINATION, RESORTS_HIDDEN_FIELDS, ROOMS_AND_GUESTS } from '../../modules/store/store.constants';
/* eslint-disable @nx/enforce-module-boundaries */
import { getSessionData } from '@marriott/mi-headless-utils';
import { usePersistentGlobalStore } from '@marriott/mi-store-utils';
import { logger } from '@marriott/mi-headless-utils';
/* eslint-enable @nx/enforce-module-boundaries */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { toRem } from '@marriott/mi-ui-library';
import { constructSearchformLabels } from '../../modules/utils/searchFormHelper';
import { getDateFormattedFromURL } from '../../utils/src/DateUtils';
import { getRoomRange } from '../../utils/src/utils';
import React from 'react';

if (global && !global.loggerInstance) {
  global.loggerInstance = logger({ requestID: '', sessionID: '' }) as CallableFunction;
}

/**
 *model ={<An AEM model with all author value>}
 * @param props
 * @returns
 */
// Use named rather than default exports.
export const SearchForm: FC<SearchFormProps> = React.memo(props => {
  const searchFormOnHomepage = props?.model?.['enableOverlayTop']; // this is the identifier which tells if the searchform is on HP or any other page
  const { model, currentLocale, isPositionedExternally, isSticky, refCallback } = props;
  const TabbedForm =
    typeof model?.['appliedCssClassNames'] === 'string' && model?.['appliedCssClassNames'].toLowerCase() === 'phoenix'
      ? true
      : false; // this tells us if the searchform is Home Page Search form or Advanced Search form

  /**
   * constructSearchformLabels function maps few labels to work with common react code ,
   * so we do not have to maintain 2 different labels for single component even if AEM
   * is sending two different label names for Advanced search form and Home page search form.
   */
  constructSearchformLabels(model);
  const callGetSessionData = props?.model?.numberOfSearchForms || props?.model?.enableOverlayTop ? true : false; // flag to determine if get session call is required.
  const updateGlobalData = usePersistentGlobalStore((state: any) => state.updateGlobalData); // function to update global data store
  const globalData = usePersistentGlobalStore((state: any) => state.globalData); // global data extracted from global data store
  /**
   * shop store to access the data
   */
  const shopStoreInitialization = useSearchFormStore((state: any) => state.shopStoreInitialization);
  const setSearchFormState = useSearchFormStore((state: any) => state.setSearchFormState); // function to update search form data in store
  const isTabbedSearchContainer = useSearchFormStore((state: any) => state?.tabbedSearchFormContainer); // extracting tabbedSearchFormContainer from search form store
  const isBaseLineSearchTabbedView = TabbedForm && !isTabbedSearchContainer?.tabbedSearchFormContainer; // variable to determine whether tabbed view is in Base line search form or from the TabbedSearchForm

  /**
   * import search from component with modification
   * and state implemention
   */

  useEffect(() => {
    setSearchFormState(
      [RESORTS_HIDDEN_FIELDS],
      {
        [RESORTS_HIDDEN_FIELDS]: {
          hotelType: model?.hotelType,
          activities: model?.resortActivities,
          amenities: model?.amenities,
        },
      },
      true
    );
  }, [model?.hotelType, model?.resortActivities, model?.amenities, setSearchFormState]);

  useEffect(() => {
    setFormLoaded(true); // setting this state as true , as search form is loaded on client side for Advanced search page
    async function getSession() {
      // Extract the sessionID cookie value from browser cookies
      const sessionID = document?.cookie?.split('sessionID=')[1]?.split(';')[0];
      // Make the client side GET session call
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await getSessionData(sessionID, false, false);
      // Initialize the zustand store with the new sessionData
      shopStoreInitialization(response?.sessionData, model);
      // Update the sessionData value in global-store
      updateGlobalData('sessionData', response?.sessionData);
    }
    // make the client side session call from SearchForm component
    if (callGetSessionData) {
      getSession();
    } else {
      // Initialize the zustand store with the sessionData available in props
      !model.isTabbedSearchForm && shopStoreInitialization(globalData?.sessionData, model);
    }
    /**
     * to set the homepage Header height
     */
    //since this element is from AEM script, need to handle it with query selector.
    const htlHeaderComp = document.querySelector('.m-header');
    const homePageBannerComp = document.querySelector('.ab');
    if (!homePageBannerComp) return;
    const resizeObserver = new ResizeObserver(() => {
      const htlHeaderHeight = htlHeaderComp?.getBoundingClientRect().height ?? HOME_PAGE_HEADER_CONST_HEIGHT;
      homePageBannerCompHeight = homePageBannerComp ? homePageBannerComp?.clientHeight : 0;
      setTopPosition(htlHeaderHeight + homePageBannerCompHeight);
    });
    resizeObserver.observe(homePageBannerComp);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const scrollPosition = useRef<number>(0);
  const searchFormContainerElRef = useRef<HTMLDivElement>(null);
  const searchFormContainerRef = useRef<HTMLDivElement>(null);
  let lastScrollPosition = 0;

  let scrollThreshold = 0;
  const [searchformScrollThreshold, setSearchformScrollThreshold] = useState(0);
  const [homePageHeaderHeight, setHomePageHeaderHeight] = useState(0);
  let homePageBannerCompHeight = 0;
  const searchFormPadding = 24;
  const [topPosition, setTopPosition] = useState(0);

  const searchFormPositionWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isPositionedExternally) {
      if (refCallback && searchFormContainerRef.current) {
        refCallback(
          searchFormContainerRef.current,
          // this is hacky and should be removed ASAP,
          // but we need to account for the form's initial top-padding
          searchFormContainerRef.current?.getBoundingClientRect().top -
            (searchFormContainerElRef?.current?.getBoundingClientRect().top || 0)
        );
      }
      return;
    }

    /**
     * to set the homepage Header height
     */
    //since this element is from AEM script, need to handle it with query selector.
    const htlHeaderComp = document.querySelector('.m-header');
    const homePageBannerComp = document.querySelector('.ab');
    const htlHeaderHeight = htlHeaderComp?.getBoundingClientRect().height ?? HOME_PAGE_HEADER_CONST_HEIGHT;
    homePageBannerCompHeight = homePageBannerComp ? homePageBannerComp?.clientHeight : 0;

    const inititalTopPosition = htlHeaderHeight + homePageBannerCompHeight;
    setTopPosition(inititalTopPosition);

    // set initial top position for search form, references htl fragment header
    setSearchformScrollThreshold(htlHeaderHeight + homePageBannerCompHeight + searchFormPadding);
    setHomePageHeaderHeight(htlHeaderHeight);
  }, []);

  const setSearchFormTopPosition = () => {
    const offsetVal = isBaseLineSearchTabbedView ? 144 : 24;
    let searchFormTopPositionValue = searchFormPositionWrapperRef?.current?.getBoundingClientRect()?.top;
    if (searchFormTopPositionValue && searchFormTopPositionValue < offsetVal) {
      searchFormTopPositionValue = 0;
    }
    if (searchFormTopPositionValue) {
      setSearchFormState(['searchFormTopPosition'], {
        searchFormTopPosition: {
          value: isBaseLineSearchTabbedView ? searchFormTopPositionValue - offsetVal : searchFormTopPositionValue,
        },
      });
    } else {
      setSearchFormState(['searchFormTopPosition'], {
        searchFormTopPosition: {
          value: window.scrollY,
        },
      });
    }
  };

  /**
   * Below use effect attaches an event for scroll event and is written in such a way that it
   * only triggers the auto scroll functionality.
   *  What is Auto Scroll functionality ? --> When user open DatePicker or any other component(dropdown modal),
   * for some users the screen size is smaller to accomodate the complete modal in the viewport with respect to search form position,
   * hence we auto scroll the searchform to the top so that the dropdown modal will is completely visible to the user.
   */
  useEffect(() => {
    if (!model.isTabbedSearchForm || isBaseLineSearchTabbedView) {
      setSearchFormTopPosition();
    }
  }, [searchFormPositionWrapperRef?.current?.getBoundingClientRect()?.top !== 0]);

  /**
   * checks the scroll position wrt threshold(header) so as to add the necessary scroll CSS
   */
  const handleScrollPosition = (updatedCurrentScrollPosition: number, scrollThreshold: number) => {
    if (updatedCurrentScrollPosition > scrollThreshold && scrollPosition.current < scrollThreshold) {
      scrollPosition.current = updatedCurrentScrollPosition;

      /**
       *makes the SearchForm full width if user scrolls and vice versa if back to original state
       */

      if (searchFormContainerElRef.current) {
        searchFormContainerElRef.current?.classList.add('color-scheme1');
      }
      if (searchFormContainerRef.current) {
        searchFormContainerRef.current?.classList.remove('search-form-wrapper');
      }
    } else if (updatedCurrentScrollPosition <= scrollThreshold && scrollPosition.current > scrollThreshold) {
      scrollPosition.current = updatedCurrentScrollPosition;
      if (searchFormContainerElRef.current) {
        searchFormContainerElRef.current?.classList.remove('color-scheme1');
      }
      if (searchFormContainerRef.current) {
        searchFormContainerRef.current?.classList.add('search-form-wrapper');
      }
    }
  };

  /**
   * function to handle the scroll state of the searchForm when user scrolled(fsd-80422)
   */
  const handleScroll = () => {
    const currentScrollPosition = window.scrollY;
    scrollThreshold = searchformScrollThreshold;

    //since this element is from AEM header or alert banner, need to handle it with query selector.
    const alertBanner = document.querySelector('.ab__desc');
    const alertBannerComp = document.querySelector('.ab');
    const alertBannerHeight = alertBannerComp?.clientHeight ?? 0;
    const htlHeaderComp = document.querySelector('.m-header');
    const htlHeaderTop = htlHeaderComp?.getBoundingClientRect().top;

    let updatedCurrentScrollPosition = currentScrollPosition;
    if (currentScrollPosition > lastScrollPosition && currentScrollPosition >= homePageHeaderHeight && htlHeaderTop) {
      updatedCurrentScrollPosition += Math.abs(htlHeaderTop);
      scrollThreshold += Math.abs(htlHeaderTop);
      if (updatedCurrentScrollPosition < scrollThreshold) {
        const calculatedTop = homePageHeaderHeight + alertBannerHeight;
        setTopPosition(calculatedTop);
      }
    }
    /**
     * Close the alert Banner on the scroll of the page
     */
    if (updatedCurrentScrollPosition > alertBannerHeight) {
      alertBanner && alertBanner?.classList.add('hide-visibility');
      alertBannerComp && alertBannerComp?.classList.add('hide-alert');
    }
    /**
     * checks the scroll previous position and current position
     */
    /**
     * remove the sticky css class if searchForm back to top
     */
    if (updatedCurrentScrollPosition === 0) {
      scrollPosition.current = 0;
      searchFormContainerElRef.current?.classList.remove('sticky-search-top');
      searchFormContainerElRef.current?.classList.remove('sticky-search-container');
      // setIsSticky(false);
      searchFormContainerElRef.current?.classList.remove('search-container-top');
      const topPosition = homePageHeaderHeight + alertBannerHeight;

      //when updatedCurrentScrollPosition === 0 , searchform should have 'search-form-wrapper' class applied.
      if (searchFormContainerElRef.current) {
        searchFormContainerElRef.current?.classList.remove('color-scheme1');
      }
      if (searchFormContainerRef.current) {
        searchFormContainerRef.current?.classList.add('search-form-wrapper');
      }
      setTopPosition(topPosition);
    } else if (updatedCurrentScrollPosition > scrollThreshold) {
      // When scroll position is greater than the top offset of the searchform
      /**
       * When scrolling down add the sticky search container class
       */
      if (updatedCurrentScrollPosition > lastScrollPosition) {
        handleScrollPosition(updatedCurrentScrollPosition, scrollThreshold);
        searchFormContainerElRef.current?.classList.add('sticky-search-container');
        searchFormContainerElRef.current?.classList.remove('sticky-search-top');
        searchFormContainerElRef.current?.classList.remove('search-container-top');
      } else {
        /**
         * When scrolling up remove the sticky search container class
         */
        handleScrollPosition(updatedCurrentScrollPosition, scrollThreshold);
        searchFormContainerElRef.current?.classList.add('sticky-search-top');
        setTopPosition(homePageHeaderHeight);
        searchFormContainerElRef.current?.classList.remove('sticky-search-container');
      }
    } else if (
      updatedCurrentScrollPosition > lastScrollPosition ||
      (updatedCurrentScrollPosition < lastScrollPosition && updatedCurrentScrollPosition < homePageHeaderHeight)
    ) {
      // When scroll position is less than or equal to the top offset of the searchform
      handleScrollPosition(updatedCurrentScrollPosition, scrollThreshold);
      /**
       * When scrolling up or scrolling down, remove the sticky search container class
       */
      searchFormContainerElRef.current?.classList.remove('sticky-search-top');
      searchFormContainerElRef.current?.classList.remove('sticky-search-container');
      searchFormContainerElRef.current?.classList.remove('search-container-top');
    }
    lastScrollPosition = currentScrollPosition;
  };

  useEffect(() => {
    if (isPositionedExternally) {
      return;
    }

    /**
     * adding the scroll event if handleScroll triggers
     * removes scroll if were back to original state
     */
    if (!TabbedForm) {
      window.addEventListener('scroll', handleScroll, { passive: true });
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [TabbedForm, searchformScrollThreshold]);
  const [formLoaded, setFormLoaded] = useState(false);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isAvailabilityScenario = props?.hideDestination && props?.hideBrandsField;
    // Retrieve the URL parameters from the window location search string
    const fromDateParam = urlParams?.get('fromDate') || urlParams?.get('fromDates');
    const fromDates = fromDateParam ? getDateFormattedFromURL(fromDateParam) : null;
    const toDateParam = urlParams?.get('toDate') || urlParams?.get('toDates');
    const toDates = toDateParam ? getDateFormattedFromURL(toDateParam) : null;
    const searchCriteria = globalData?.sessionData?.cacheData?.data?.AriesSearch?.searchCriteria;
    // Attempt to retrieve the property code from either The global session data object if available or URL parameters if the global data is not available.
    const propertyCode = urlParams?.get('propertyCode') || searchCriteria?.propertyId;
    const fromDate = fromDates;
    const toDate = toDates;
    const numofRooms = urlParams?.get('numberOfRooms') || searchCriteria?.availabilityRequestVO?.numRooms;
    const numofAdults = urlParams?.get('numberOfGuests') || searchCriteria?.availabilityRequestVO?.numAdultsPerRoom;
    const numofChildren = urlParams?.get('childrenCount') || searchCriteria?.availabilityRequestVO?.numChildrenPerRoom;
    // Check if both 'hideDestination' and 'hideBrandsField' props are true, and if a property code exists.
    if (isAvailabilityScenario && propertyCode) {
      setSearchFormState(
        [DESTINATION],
        {
          [DESTINATION]: {
            displayText: propertyCode,
            destinationAddressPlaceId: propertyCode,
          },
        },
        true
      );
      // Update the search form state to include additional details for the destination field.
      setSearchFormState(['destinationFieldDetails'], {
        destinationFieldDetails: {
          recentlyViewedPopertyCode: propertyCode,
          isRecentSearchSelected: true,
        },
      });
    }
    if (isAvailabilityScenario && fromDate && toDate) {
      const lengthOfStay = searchCriteria?.availabilityRequestVO?.lengthOfStay;
      setSearchFormState([DATES], {
        [DATES]: {
          fromDate: getDateObj(fromDate),
          toDate: getDateObj(toDate),
          flexible: searchCriteria?.availabilityRequestVO?.flexibleDate,
          numberOfNights:
            fromDate && toDate
              ? Number(getTotalNumberOfDays(moment(fromDate), moment(toDate))?.toFixed(0) || 1)
              : lengthOfStay,
          lengthOfStay: fromDate && toDate ? getTotalNumberOfDays(moment(fromDate), moment(toDate)) : lengthOfStay,
        },
      });
    }
    if (isAvailabilityScenario && numofRooms) {
      setSearchFormState([ROOMS_AND_GUESTS], {
        [ROOMS_AND_GUESTS]: {
          numRooms: getRoomRange(numofRooms),
          ...(numofAdults && { numAdultsPerRoom: parseInt(numofAdults, 10) }),
          ...(numofChildren && { numChildrenPerRoom: parseInt(numofChildren, 10) }),
        },
      });
    } else return;
  }, [globalData?.sessionData]);

  const getSearchformComponent = (TabbedForm: boolean) => {
    if (TabbedForm) {
      return (
        <div ref={isBaseLineSearchTabbedView ? searchFormPositionWrapperRef : undefined}>
          <StyledSearchForm
            data-component-name="o-shop-searchform"
            data-testid="searchform"
            ref={searchFormContainerElRef}
            className={clsx(
              'd-flex',
              model.isTabbedSearchForm !== true && 'p-3 pt-5',
              'document_search_form_container_el_ref'
            )}
          >
            <StaticDataContextProvider value={{ ...model, currentLocale }}>
              <div
                ref={searchFormContainerRef}
                className={clsx(
                  'container px-0',
                  model.isTabbedSearchForm ? '' : 'search-form-wrapper',
                  'document_search_form_container',
                  'color-scheme1'
                )}
              >
                {!formLoaded ? (
                  <></>
                ) : (
                  <SearchFormView
                    {...{
                      sessionData: globalData,
                      model,
                      currentLocale,
                      hideBrandsField: props.hideBrandsField,
                      hideDestination: props.hideDestination,
                    }}
                  ></SearchFormView>
                )}
              </div>
            </StaticDataContextProvider>
          </StyledSearchForm>
        </div>
      );
    } else {
      return (
        <StyledSearchFormWrapper
          data-component-name="o-shop-searchform"
          data-testid="searchform"
          ref={searchFormPositionWrapperRef}
          className={clsx(props?.model?.['appliedCssClassNames'] ? '' : 'm-container-fullbleed', {
            'search-form-position': searchFormOnHomepage,
          })}
          topOffset={toRem(topPosition)}
        >
          <StyledSearchForm
            ref={searchFormContainerElRef}
            className={clsx(
              'd-flex',
              model.isTabbedSearchForm !== true && 'p-3 pt-4',
              'document_search_form_container_el_ref',
              'pt-0 pb-0',
              {
                'search-wrapped': isPositionedExternally,
                'sticky-search-wrapped color-scheme1': isPositionedExternally && isSticky,
              }
            )}
            style={{ top: toRem(topPosition) }}
          >
            <StaticDataContextProvider value={{ ...model, currentLocale }}>
              <div
                ref={searchFormContainerRef}
                className={clsx(
                  'container px-0',
                  model.isTabbedSearchForm ? '' : 'search-form-wrapper',
                  'document_search_form_container',
                  { 'color-scheme1': !isPositionedExternally || !isSticky }
                )}
              >
                <SearchFormView
                  {...{
                    sessionData: globalData,
                    model,
                    currentLocale,
                    hideBrandsField: props.hideBrandsField,
                    hideDestination: props.hideDestination,
                  }}
                ></SearchFormView>
              </div>
            </StaticDataContextProvider>
          </StyledSearchForm>
        </StyledSearchFormWrapper>
      );
    }
  };

  /**
   * TODO: All store calculation will be done as part of this file
   * We will pass proccess data to useSEarchForm in the form of props
   */
  return <>{getSearchformComponent(TabbedForm)}</>;
});

//Config for aem editable components
export const SearchFormConfig = {
  emptyLabel: 'SearchForm',
  isEmpty: false,
  resourceType: `mi-aem-shop-spa/components/content/homepagesearch/v1/homepagesearch`,
};
//Component wrapped with editable component for aem authoring
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SearchFormEditable = (props: any) =>
  props.cqPath.includes('experiencefragment') ? (
    <SearchForm {...props} />
  ) : (
    <EditableComponent config={SearchFormConfig} {...props}>
      <SearchForm {...props} />
    </EditableComponent>
  );
