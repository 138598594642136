/**
 * A regular expression that matches any character that is NOT an alphanumeric character (a-z, A-Z, 0-9),
 * or a Chinese, Japanese, or Korean (CJK) character. It covers the following Unicode ranges:
 *
 * - \u3040-\u30FF: Hiragana and Katakana (Japanese)
 * - \u3400-\u4DBF: CJK Unified Ideographs Extension A (Chinese, Japanese, and Korean)
 * - \u4E00-\u9FFF: Common CJK Unified Ideographs (Chinese, Japanese, and Korean)
 * - \uF900-\uFAFF: CJK Compatibility Ideographs (Chinese, Japanese, and Korean)
 * - \uFF66-\uFF9F: Half-width Katakana (Japanese)
 *
 * The `g` flag is used to ensure that all occurrences of these characters in a string are matched.
 *
 * Reference: https://stackoverflow.com/a/43419070/5564345
 */
export const specialCharsRegex = /[^a-zA-Z0-9\u3040-\u30FF\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF\uFF66-\uFF9F]+/g;
