import { DateYMDString } from '@marriott/mi-apollo-client-utils';
import {
  DE_LOCALE,
  FR_LOCALE,
  IT_LOCALE,
  JP_LOCALE,
  KR_LOCALE,
  PT_BR_LOCALE,
  RU_LOCALE,
  TH_LOCALE,
} from '../../modules/constants';

export const constants = {
  DEFAULT_LANG: 'en_US',
  DEFAULT_COUNTRY: 'US',
  ITEM_PATH_ROOT: 'root/responsivegrid',
  ITEM_PATH_HEADER: 'root/header-responsivegrid',
  ITEM_PATH_FOOTER: 'root/footer-responsivegrid',
  PAGE_LOADED: 'Page Loaded',
  PAGE_LOADING: 'Page Loading',
  ROUTING_CONFIG: 'routingConfig',
  APPLICATION_NAME: 'shop',
  SPA_APP_NAME: 'shop-spa',
  PAGE_NAME: 'HQV',
  EXPERIENCE_SEGMENT: ['NOT Logged In'],
  HQV_SEOURL: '/search/hotelQuickView',
  SESSION_SEARCH_KEYS:
    'sessionToken,rewardsId,memberLevel,name,accessToken,consumerID,propertyId,AriesRewards.savedHotelList,AriesReservation.propertyId,AriesReservation.errorMessages,AriesCommon.prop_name,AriesCommon.savedProps,AriesCommon.revisionToken,AriesCommon.memState,AriesCommon.ptsBal,AriesCommon.search_destination_city,AriesCommon.search_destination_country,AriesCommon.search_destination_state,AriesSearch.search_availability_search,AriesSearch.search_date_type,AriesSearch.search_location_or_date_change,AriesSearch.rememberedMemberLevel,AriesSearch.searchCriteria,AriesSearch.search_keyword,AriesSearch.search_date_check_out_day_of_week,AriesSearch.search_date_check_in_day_of_week,AriesSearch.search_advance_purchase_days,AriesSearch.propertyFilterCriteria,AriesSearch.search_is_weekend_stay,AriesSearch.search_criteria_changed,AriesSearch.search_google_places_destination,AriesSearch.propertyRecordsCount,AriesSearch.propertyId,AriesSearch.errorMessages,AriesSearch.search_dates_flexible',
};

export const DATE_FORMAT_DEFAULT = 'ddd, MMM DD'; // Wed, Jan 20
export const DATE_FORMAT_VALUE = 'MM/DD/YYYY'; // 01/20/2021
export const DATE_FORMAT_ISO = 'YYYY-MM-DDT00:00:00.000+0000';
export const ROOM_THRESHOLD_1 = 4;
export const ROOM_THRESHOLD_2 = 10;
export const RANGE_4_9 = '4-9';
export const RANGE_10_25 = '10-25';
export const RANGE_26_PLUS = '26+';

// Query fallback constants
export const DEFAULT_FALLBACK_PROPERTY_ID = 'NYCVC';
export const DEFAULT_FALLBACK_BRAND_CODE = 'CY';
export const DEFAULT_FALLBACK_START_DATE: DateYMDString = '2024-06-15';
export const DEFAULT_FALLBACK_END_DATE: DateYMDString = '2024-06-20';
export const DEFAULT_FALLBACK_LATITUDE = 40.752132;
export const DEFAULT_FALLBACK_LONGITUDE = -73.981665;
export const dailyResortFeeLabel = 'Daily Resort Fee';
export const dailyDestinationFeeLabel = 'Daily Destination Amenity Fee';
export const GOOGLE_MAPS_MAP_ID = 'ec5bb275520a7fc8';
export const rateCategoryCodeForPrice = 'standard';
export const rateCategoryCodeForEEOPrice = 'special';
export const clickTrackingLoc = 'HQV AEM';
export const DEFAULT_FALLBACK_CURRENCY = 'USD';
export const MAP_FLEXIBLE_MARKER_SIZE = '22px';
export const MAP_MARKER_SIZE = '14px';
export const MAP_SEARCHFORM_HEIGHT = 195;
export const DEFAULT_CARD_POSITION = -132;
export const FEES_CARD_POSITION = -156;
export const LABEL_CARD_POSITION = -145;
export const LABEL_FEE_CARD_POSITION = -165;
export const PIN_HEIGHT = 50;
export const PIN_FLEXI_HEIGHT = 54;
export const DEFAULT_MARKER_HEIGHT = 30;
export const DEFAULT_MARKER_WIDTH = 75;
export const FLEXI_MARKER_WIDTH = 75;
export const ROOMS_GUESTS_HEADER_CHARACTER_LIMIT = 11;
export const ROOMS_GUESTS_SUB_CHARACTER_LIMIT = 8;
export const REGION_TO_LOCALE = { 'language-masters': ['en'] };
export const GQL_OPT_SIGN = [
  {
    operationName: 'phoenixShopHotelAmenities',
    signature: '99af3ddf79249151601137784dc42cf169234b3061757084daa263b00bd140fd',
  },
  {
    operationName: 'phoenixShopHQVPhotogalleryCall',
    signature: 'b2e278702da54e6348bd5733a5ab3c3fe353bd46aeb1ca1230c6960f54bd15ba',
  },
  {
    operationName: 'phoenixShopHQVPhotogalleryCall',
    signature: 'cb4d1afba0c35917fcad3843b3fa6cdec8467234695443d3d06767e0ac4fdcb5',
  },
  {
    operationName: 'phoenixShopSearchLowestAvailableRatesByGeoQuery',
    signature: '7daef5a6e1c08f425849ee4fedd9ac73112455a5a74c69c4ee6f1becbd793e73',
  },
  {
    operationName: 'phoenixShopSearchLowestAvailableRatesByDestinationQuery',
    signature: '9529b8b088816eac065866076d55f5a148f9db7dec45b68763ff1092d99bdbd1',
  },
];

// TODO: Please clean up and migrate necessary values to constants (above)
export const oldBookConstants = {
  DEFAULT_LANG: 'en_US',
  DEFAULT_COUNTRY: 'US',
  BASE_IMG_URL:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiOAMAANUAz5n+TlUAAAAASUVORK5CYII=',
  DOWNSIZE_PARAM: '?output-quality=70&interpolation=progressive-bilinear&downsize=',
  IMAGE_SIZE: {
    desktop: '1440:480',
    tablet: '768:*',
    mobile: '*:*',
  },
  VIEWPORT_SIZE: {
    desktop: '1200px',
    tablet: '768px',
    mobile: '576px',
  },
  HOTEL_IMAGE_SIZE: {
    desktop: '459:258',
    tablet: '285:285',
    mobile: '*:187',
  },
  CLASS_IDENTIFIER: 'mobile-height',
  MAX_VIEWPORT_MOBILE: 600,
  BREAKPOINT_TABLET: 768,
  BREAKPOINT_DESKTOP: 992,
  BREAKPOINT_LARGE_DESKTOP: 1200,
  ENROLLMENT_FAILURE: 'enrollmentFailure',
  DUPLICATE_EMAIL: 'duplicateEmail',
  MATCH_KEY_ERROR: 'matchKeyError',
  MATCH_KEY: 'mrprogram.matchkey.error',
  SUCCESS: 'success',
  bookingError: 'bookingError',
  ERROR: 'ERROR',
  SNA_TYPE: 'sna',
  ROOM_UPGRADE_TYPE: 'roomUpgrade',
  ROOM_UPGRADE_DETAILS_ID: 'roomUpgradeDetails',
  SNA_CUTOFF_HOUR: 14,
  DUPLICATE_EMAIL_ERROR: 'email.duplicate.marriott.account.already.exists',
  PLUS_PAYMENT_METHOD_CODE_TYPE: '25.PMT',
  PAYMENT_METHOD_CODE_TYPE_DEPOSITE: '1.PMT,4.PMT,8.PMT',
  PAYMENT_METHOD_CODE_TYPE_GUARANTEE: '2.PMT,3.PMT,5.PMT,19.PMT,22.PMT,25.PMT,29.PMT,30.PMT,32.PMT,40.PMT',
  NAME_REVERSE_COUNTRY_LIST: [null],
  IS_LOCALE_IT: 'it_IT',
  IS_LOCALE_JP: 'ja_JP',
  IS_LOCALE_CN: 'zh_CN',
  DATE_REGEX: /\d{4}([\\/.-])\d{2}\1\d{2}/g, //regex to check the date format in yyyy-mm-dd
  EMAIL_REGEX:
    '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  TI_REDIRECT_STATIC_LINK: 'https://etravelprotection.com/marriott1',
  TI_LINK_ARIA_LABEL: 'Allianz Travel Insurance',
  DAY_DATE_FORMAT: 'ddd, MMM D, YYYY',
  DAY_MONTH_FORMAT: 'dddd, MMMM D, YYYY',
  DATE_FORMAT: 'MM/DD/YYYY',
  TIME_FORMAT: 'hh:mm a',
  MONTH_DATE_FORMAT: 'MMMM D, YYYY',
  PROPERTY_URL: '/hotels/travel/',
  PROPERTY_LOCATION_URL: '/hotels/maps/travel/',
  HOTEL_MAP_POST_URL: 'directPageRequest=true/',
  PLAY_SERVICE_PATH: 'https://phoenix-bookdev1.ose-dev39-red.aws-use1.cloud.marriott.com',
  MI_FOUNDATION_JS: '/aries/dd578d02c/standards/js/mi-foundation.js',
  MI_CORE_JS: '/aries/e40f6404963a1913580c/components/mi-core/mi-core.js',
  MI_COMMON_JS: '/aries/ee81380581baa484d0f3/components/mi-common/mi-common.js',
  MI_VENDOR_JS: '/aries/c5932cf546474c4c6ca7/components/mi-vendor/mi-vendor.js',
  MEMBER_CARD: 'memberCard',
  CHECK_IN_ELIGIBLE: 'checkInEligible',
  PHYSICAL_CHECKED_IN: 'PhysicalCheckedIn',
  SHOW_SMS_LOCALES: ['it_IT', 'zh_TW', 'ko_KR', 'ar_AE', 'ja_JP', 'ru_RU', 'pt_BR', 'en_AU'],
  OPTION_WIDGET_MENU: 'Option Widget Menu',
  ITEM_PATH_ROOT: 'root/responsivegrid',
  ITEM_PATH_HEADER: 'root/header-responsivegrid',
  ITEM_PATH_FOOTER: 'root/footer-responsivegrid',
  ENV_DEV: 'development',
  OCJ_MODAL: 'ocj-modal',
  IMG_ALT_TEXT: 'Title',
  SLIDE_PREV_BUTTON_LABEL: 'Previous slide',
  SLIDE_NEXT_BUTTON_LABEL: 'Next slide',
  PARTNER_SITES: ['/ritz', '/bulgari'],
  PARAM_ANOTHER_ROOM: 'isRepeatBooking=true',
  BONVOY_BANNER: 'bonvoyBanner',
  MINICARD: 'miniCard',
  INTERNAL_LINK: 'internal',
  COBRAND_NON_POINTS_MATH: 'cobrandNonPointsMath',
  OPTION_WIDGET: 'optionWidget',
  RESCONFO_TILE: 'ResConfo Tile',
  CLIENT_ID: 'mcom_rand',
  GUEST_ROOMS_CATEGORY: 'guest-rooms',
  HOTEL_VIEW_CATEGORY: 'hotel-view',
  SUITES_CATEGORY: 'suites',
  SERVICES_CATEGORY: 'services',
  ASPECT_RATIO_CLASSIC: 'CLASSIC',
  FIRST_NIGHT_PER_UNIT_RATE_MODE: 'FIRST_NIGHT_PER_UNIT',
  ASCENDING_DIRECTION: 'ASC',
  CANCELLATION_CP: 'CP',
  CANCELLATION_CF: 'CF',
  AR: 'AR',
  PREFERENCES_EXCEPTION_CODE: ['S5', 'D7', 'D9', 'N8', 'U2', 'W5', 'N2'],
  PREFERENCES_NOTED_CODE: ['I2', 'N4'],
  PREFERENCES_ERS_CODE: ['5CAT005', '2CAT002', '12CAT12', '9CAT009', '11CAT11'],
  PREFEREENCE_REQUEST_STATUS: 'request-only,requested,on request,noted',
  PREFEREENCE_DENIED_STATUS: 'denied,not offered,invalid-date',
  PREFEREENCE_GUARANTEED_STATUS: 'guaranteed,guaranteeable',
  HEADER_GQL_OP_Name: 'graphql-operation-name',
  HEADER_GQL_OP_Signature: 'graphql-operation-signature',
  MOBILE_NUMBER: 'mobile-number',
  COUNTRY_CODE: 'countryCode',
  US: 'United States (1)',
  ENGLISH: 'English',
  DEFAULT_LOCALE: 'en',
  DEFAULT_DIALING_CODE: '1',
  USA: 'USA (1)',
  LANGUAGE: 'language',
  REQUIRED: 'Required',
  SHARE_BY_SMS: 'ShareBySMS',
  SHARE_BUTTON: 'shareButton',
  PRINT_BUTTON: 'printButton',
  ADD_TO_CALENDAR_BUTTON: 'addToCalendarButton',
  GOOGLE_CALENDAR: 'googleCal',
  RESERVATION_TEXT: 'text=Reservation at',
  RATE_CODES: ['31.RPT', '29.RPT', '30.RPT', '33.RPT', '32.RPT'],
  ESTIMATED_TAX_CODE: 'total-taxes-per-quantity',
  TOTAL_CASH_CODE: 'grand-total',
  CASH_CODE: 'sub-total-per-quantity',
  CASH_UPGRADE_CODE: 'points-plus-cash-upgrade-per-unit',
  CASH_WITH_POINTS: 'cash-and-points-per-unit',
  POINTS_CODE: 'points-per-quantity',
  ZCERTIFICATE_RATE_PLAN: '11.RPT',
  SUPPRESSED_RATE: '##.##',
  EMAIL: 'EMAIL',
  CALENDAR_URL: 'https://calendar.google.com/calendar/r/eventedit?',
  UPGRADE_TO_TEXT: 'Upgrade to',
  FREE_FIFTH_NIGHT_AVAILABLE: 'free5thNight',
  POINTS_ADVANCE: 'pointsAdvanceAlertMessage1',
  POINTS_ADVANCE_WITH_FREE_NIGHT: 'pointsAdvanceAlertMessage2',
  CARD_TEXT: 'card',
  REDEMPTION_FALLBACK: 'There was an issue loading your Stay Details. Check your email for confirmation!',
  EMAIL_URL: 'phoenix-email/v1/createTemplateAndSendEmail',
  SMOKE_FREE: 'smoke-free',
  EVENT_DRIVEN_DATALAYER: 'eventdriven',
  PETS_ALLOWED: 'pets-allowed',
  PHOENIX_REQUESTID: 'PHOENIX_REQUESTID',
  DATALAYER_FALLBACK_URL: '/aries-common/v1/dataLayer.comp',
  BONVOY_MEMBER: 'Bonvoy Member',
  GOOGLE_MAP_URL: 'https://www.google.com/maps/search/?api=1&query=',
  HOTEL_REVIEWS_URL_PATH: '/hotels/hotel-reviews/',
  HOTEL_MESSAGE: 'hotelmessage',
  ALERT: 'alert',
  RATE_DETAILS: 'Rate Details',
  ROOM_DETAILS: 'Room Details',
  REDIRECT_URL: '/search/default.mi',
  TARGET_BLANK: '_blank',
  RRD_TYPE: 'rrd',
  PARKING_TYPES: ['On-site parking', 'Valet Parking', 'Off-site parking'],
  STAY: 'stay',
  ROOM_LOCATION: 'roomLocation',
  ROOM_FEATURES: 'roomFeatures',
  ACCESSIBILITY: 'accessibility',
  ROOM_LOCATIONS_CODES: ['H1', 'L2', 'N1'],
  ROOM_FEATURES_CODES: ['C2', 'R1', 'V9', 'Y2', 'F2', 'B5'],
  BATHROOM_CODES: ['E6'],
  STAY_CODES: ['E1'],
  CHARGEABLE_CODES: ['C2', 'R1', 'P8'],
  FEATHER_CODES: ['V9', 'B5'],
  FLOOR_CODES: ['L2', 'H1'],
  PILLOW_CODES: ['V9', 'F2', 'Y2'],
  PET_POLICY_CODE: 'P8',
  CRIB_CODE: 'C2',
  ROLLAWAY_BED_CODE: 'R1',
  FEATHER_PILLOW_CODE: 'V9',
  APPLY_TO_ALL: 'Apply to all rooms',
  PLEASE_NOTE: 'Please note Room',
  NON_GUARANTEED_ROOM: 'Non Guaranteed Room',
  SOLD_OUT: 'Sold out/Non Guaranteed Room',
  GUARANTEED_ROOM: 'Guaranteed Room',
  STICKY_BAR_TIMER: 900,
  FIVE_MINUTE_TIMER: 300,
  PREF_ERROR: 'prefError',
  RULE_CODE_DE: 'DE',
  RULE_CODE_VZ: 'VZ',
  HK_EXCLUDED_BRAND_LIST: ['ER', 'MV', 'RZ', 'EB', 'WH', 'LC', 'XR', 'JW', 'BG'],
  HK_FREQUENCY: 'Every Other Day',
  HOUSEKEEPING_OPT_IN: 'J9',
  HOUSEKEEPING_OPT_OUT: 'J8',
  HOUSEKEEPING_CODES: ['J8', 'J9'],
  HOUSEKEEPING: 'HK',
  HK_PLAN_A: 'A',
  HK_PLAN_B: 'B',
  HK_LOOKUPID_KEY_BASELINE: '2071',
  HK_LOOKUPID_KEY_FREQUENCY: '2069',
  HK_PLANB_PARTICIPATION: 'Yes - No Scheduled Service',
  HK_PLANA_PARTICIPATION: 'Yes - Housekeeping provided',
  HK_NOT_PARTICIPATING: 'Not Participating',
  NUMBERONLYPATTERN: /\[0-9]*/,
  BILLING_ZIP_CNTRY_LIST: ['US', 'CA'],
  INDIA_COUNTRY_CODE: 'IN',
  CHINA_COUNTRY_CODE: 'CN',
  PAGE_LOADED: 'Page Loaded',
  PAGE_LOADING: 'Page Loading',
  RRD: 'reviewDetails',
  RRD_COMPONENT_COUNT: 8,
  CONFIRMATION_COMPONENT_COUNT: 11,
  CANADA_COUNTRY_CODE: 'CA',
  AUSTRALIA_COUNTRY_CODE: 'AU',
  USA_COUNTRY_CODE: 'US',
  AMEX_CARD_CODE: 'A',
  TI_ACCEPT: 'ti_accept',
  TI_REJECT: 'ti_reject',
  MOBILE_APP: 'Mobile Site',
  DESKTOP_APP: 'Desktop App',
  POINT_TYPE: 'POINTS',
  CASH_TYPE: 'CASH',
  MI: 'mi',
};

export const DATALAYER_CONSTANT = {
  headerKeys: [
    'accept-language',
    'user-agent',
    'x-request-id',
    'x-service-id',
    'x-forwarded-host',
    'x-host',
    'x-xm-site-id',
    'x-lite-site',
    'x-request-uri',
    'x-request-host',
    'sec-fetch-user',
    'x-forwarded-for',
    'x-schema',
    'sec-fetch-mode',
    'x-forwarded-port',
    'sec-fetch-site',
    'sec-fetch-dest',
    'x-forwarded-proto',
    'referer',
    'forwarded',
    'messageid',
    'x-akamai-edgescape',
    'x-akamai-device-characteristics',
    'x-client-ip',
    'x-akamai-config-log-detail',
    'akamai-origin-hop',
    'digitalserviceswitch',
  ],
};

export const monthsArray = [
  { monthLabel: 'Jan' },
  { monthLabel: 'Feb' },
  { monthLabel: 'Mar' },
  { monthLabel: 'Apr' },
  { monthLabel: 'May' },
  { monthLabel: 'Jun' },
  { monthLabel: 'Jul' },
  { monthLabel: 'Aug' },
  { monthLabel: 'Sep' },
  { monthLabel: 'Oct' },
  { monthLabel: 'Nov' },
  { monthLabel: 'Dec' },
];

export const EEO_MEMBER_CORP_CODE: Record<string, string> = {
  P: 'E0P',
  G: 'E0G',
  S: 'E0S',
};

export const EEO_MEMBER_CODE = ['E0P', 'E0S', 'E0G'];

// adding default locale
export enum hardCodedAllLocale {
  en = 'en-US',
  gb = 'en-GB',
  de = 'de-DE',
  cn = 'zh-TW',
  ko = 'ko-KR',
  ar = 'ar-AE',
}

// change miles value to kilometers
export const MILESTOKILOMETERS = 1.60934;

// brands that need to redirected to the landing page instead of the reviews page
export const HWSLandingPageRedirectionBrands = ['RZ', 'MV', 'DS', 'BG'];

// link to redirect from reviews section if going to reviews page
export const viewHotelReviewsLink = '/hotels/travel/';

// link to redirect from reviews section if going to HWS Landing Page
export const viewHotelWebsiteLink = '/hotels/travel/';

// part of link to enable redirection to HWS reviews section
export const hotelsSublink = '/hotels/';

// part of link to enable redirection to HWS reviews section
export const reviewsSubLink = '/reviews/';

// part of link to enable redirection to HWS overview page
export const overviewSubLink = '/overview/';

// to pass as an argument to redirection hook
export const reviewsRedirectionVarValue = 'reviews';

// convert meters distance to miles
export const METERSTOMILES = 1609.344;

//convert miles distance to km
export const METERSTOKM = 1000;

// to pass as an argument to redirection hook
export const trcRedirectionVarValue = 'trc';

// URL to redirect to ritz carlton correctly
export const trcRedirectionURL = 'http://www.ritzcarlton.com/';

// Locale key for comparison in redirection hook
export const chinaLocale = 'zh-cn';

export const searchFormClickTrackingLoc = 'Search Form';
export const meetingSearchFormClickTrackingLoc = 'Meeting Search Form';
export const autosuggestionsClickTrackingLoc = 'Suggested place:';
export const datepickerResetClickTrackingLoc = 'Reset button';
export const cityPicklistClickTrackingLoc = 'City Picklist';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const keyMappings: any = {
  quickFilters: 'amenities',
  transportation: 'transportation-types',
  events: 'meetings-and-events',
  activities: 'activities-on-site',
};

export const locationDescriptionFilterToDisplayOnHQV = 'LOCATION';
export const resortFeeDescriptionFilterToDisplayOnHQV = 'RESORT_FEE_DESCRIPTION';
export const destinationFeeDescriptionFilterToDisplayOnHQV = 'DESTINATION_FEE_DESCRIPTION';
export const surchargeOrdinanceCostDescriptionFilterToDisplayOnHQV = 'SURCHARGE_ORDINANCE_COST_DESCRIPTION';
export const ADV_SEARCH_FORM_THREE_TO_TEN_ROOMS_COUNT_ERROR_CODE =
  'search-error-messages|room.count.between3and10.invalid';
export const ADV_SEARCH_FORM_TEN_ROOMS_COUNT_ERROR_CODE = 'search-error-messages|room.count.between10and25.invalid';
export const ADV_SEARCH_FORM_CURR_DATE_ROOM_COUNT_ERROR_CODE =
  'search-error-messages|room.count.between3and26.invalid.today';
export const ADV_SEARCH_FROM_COMPI_ID = 'componentId';
export const ADV_SEARCH_FROM_THREE_TO_TEN_ROOMS = 'threeToTenRoomCountErrorCode';
export const ADV_SEARCH_FORM_MORE_THAN_TEN_ROOMS_COUNT_ERROR_CODE = 'moreThanTenRoomCountErrorCode';
export const ADV_SEARCH_FORM_TEN_TO_TWENTY_FIVE_ROOMS_COUNT_ERROR_CODE = 'tenToTwentyFiveRoomCountErrorCode';
export const ADV_SEARCH_FORM_VACATION_ROOM_FLIGHT_LINK =
  'https://www.vacationsbymarriott.com/default.aspx?site=us&nav=marriottvacations&air1=true&room1=true&car1=false';
export const ADV_SEARCH_FORM_VACATION_ROOM_CAR_LINK =
  'https://www.vacationsbymarriott.com/default.aspx?site=us&nav=marriottvacations&air1=false&room1=true&car1=true';

export const tabSelect = {
  hotels: 'Hotels',
  hotelsTab: 'Baseline Search Form',
  meetingsTab: 'Meetings',
  events: 'Events',
};

export const checkInFutureDateError = 'checkinDateFutureNotAllowedInvalid';

export const checkOutFutureDateError = 'checkoutDateFutureNotAllowedInvalid';

export const checkInPastDateError = 'checkinDatePastNotAllowedInvalid';

export const checkOutPastDateError = 'checkoutDatePastNotAllowedInvalid';

export const travelAgentsError = 'corpCodeTravelAgentsError';

export const groupPartnersError = 'corpCodeGroupPartnersError';

export const travelAgentsErrorClickTrackingValue = 'Travel agents error link clicked! | Adv Search Page | internal';

export const groupPartnersErrorClickTrackingValue = 'Group Partners error link clicked! | Adv Search Page | internal';

export const roomCountBetweenThreeAndTenError = 'roomCountBetween3and10Invalid';

export const flexibleRoomCountMoreThanThreeError = 'flexibleDateRoomCountInvalid';
export const locationBlankInvalidError = 'locationBlankInvalid';
export const corporateCodeFormatLengthInvalidError = 'corporateCodeFormatLengthInvalid';
export const corporateCodeFormatInvalidError = 'corporateCodeFormatInvalid';

export const LATIN_NAME_REGEX = /^[A-Za-z0-9]*$/;
export const REWARDS_REDEMPTION_CLUSTER_CODES_HQV = ['MRW', 'P17', '39E', 'MW1'];
export const corpCodeLocalVal = 'Corp/Promo Code';
export const corpCodeVal = 'corp';
export const roomCountFourToNine = '4-9';
export const roomCountTenToTwentyFive = '10-25';
export const RateNodeTypeToBeUsed = 'lowest-average-nightly-rate';
export const maxOccupancyCode = 'closedMaxOccupancyExceeded';
export const STATUS_MAX_OCCUPANCY_EXCEEDED = 'ClosedDueToMaxOcc';
export const DefaultValue = 'default';
export const localizedFormattingRequiredLocales = [JP_LOCALE, KR_LOCALE, RU_LOCALE];
export const MRW_CODE = 'MRW';
export const P17_CODE = 'P17';
export const SPECIAL_CODE = 'special';
export const STANDARD_CODE = 'standard';
export const RATE_UNIT = 'rateUnit';
export const RATE_MODE = 'rateMode';
export const POINTS = 'points';
export type DTT_HEADERS_TYPE = {
  'uxl-mgp'?: string;
  mgp?: string;
};
export const DTT_HEADERS: DTT_HEADERS_TYPE = {
  'uxl-mgp': 'false',
};
export const DTT_UXL_HEADERS: DTT_HEADERS_TYPE = {
  'uxl-mgp': 'true',
};
export const photoSubCategories = [
  'hotelView',
  'suites',
  'guestRooms',
  'recreationAndFitness',
  'dining',
  'eventsAndMeetings',
  'features',
  'golf',
  'services',
  'spa',
  'villas',
  'activities',
  'weddings',
];
export const currencyLocales = [
  {
    currency: 'USD',
    locale: 'en-US',
  },
  {
    currency: 'AUD',
    locale: 'en-AU',
  },
  {
    currency: 'CAD',
    locale: 'en-CA',
  },
  {
    currency: 'HKD',
    locale: 'en-US',
  },
  {
    currency: 'MXN',
    locale: 'es-MX',
  },
  {
    currency: 'GBP',
    locale: 'en-US',
  },
  {
    currency: 'CNY',
    locale: 'zh-CN',
  },
  {
    currency: 'JPY',
    locale: 'en-US',
  },
  {
    currency: 'EUR',
    locale: 'en-US',
  },
  {
    currency: 'RUB',
    locale: 'ru-RU',
  },
  {
    currency: 'ILS',
    locale: 'en-US',
  },
  {
    currency: 'IDR',
    locale: 'id-ID',
  },
  {
    currency: 'KRW',
    locale: 'en-US',
  },
  {
    currency: 'ZAR',
    locale: 'en-ZA',
  },
];
export const CityPickListDesktopCountForRecentSearches = 5;
export const CityPickListMobileCountForRecentSearches = 4;
export const CountForRecentSearches = 3;
export const MIN_TAB_SIZE = 767;
export const ExploreDestinationDisabledOnLocales = [
  KR_LOCALE,
  FR_LOCALE,
  IT_LOCALE,
  RU_LOCALE,
  PT_BR_LOCALE,
  DE_LOCALE,
];
export const ELEMENT_ACNHOR = 'a';
export const ELEMENT_INPUT = 'input';
export const ELEMENT_BUTTON = 'button';
export const LOCALES_FOR_ROOMS_AND_GUEST_DISPLAY_VALUE_CHANGES = ['es-es', 'it-it', 'fr-fr', 'pt-br', 'ru-ru'];

export const ADDRESS_TYPE_COUNTRY = 'country';

export const errorMessageCodes = ['LAR-1001'];

export const HWSRedirectionVarValue = 'hws';

export const ARABIC_PERIODS = {
  MORNING: 'صباحًا',
  NOON: 'ظهرًا',
  AFTERNOON: 'عصرًا',
  EVENING: 'مساءً',
};
