/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { constants } from '../../constants';
import { inspect } from 'util';
import { logger } from './logger';
import moment, { Moment } from 'moment';
import { addSubDirectoryPrefix, getLanguage } from './subDirectoryFunctions';
import { LOCALE_ZH_CN } from '@marriott/mi-ui-library';
import { getDeployedEnvType } from '@marriott/mi-apollo-client-utils';
import { JP_LOCALE, KR_LOCALE } from '../../modules/constants';

declare global {
  // eslint-disable-next-line no-var
  var loggerInstance: CallableFunction;
}

const { NEXT_PUBLIC_DYNAMIC_ROUTING_URL } = process.env;

const { ROUTING_CONFIG, APPLICATION_NAME, EXPERIENCE_SEGMENT, SESSION_SEARCH_KEYS } = constants;
const { NEXT_PUBLIC_DEFAULT_LANG } = process.env;
export const canUseDOM = !!(typeof window !== 'undefined' && window.document);

export function getItemFromPageModel(pageModel: any, itemPath: string) {
  const parts = itemPath.split('/');
  let obj = pageModel;

  for (let i = 0; i < parts.length && obj; i++) {
    obj = (obj[':items'] || {})[parts[i]];
  }

  return obj || {};
}

export const getProcessEnvs = () => {
  if (canUseDOM) {
    const envTag = document.getElementById('__SERVERENV__');
    if (envTag) {
      const envObject = JSON.parse(envTag.innerHTML);
      return envObject;
    }
    return process.env;
  }
  return process.env;
};

const { SESSION_APP_CALL_URL } = process.env;
const { NEXT_PUBLIC_FEATURE_FLAG_URL } = process.env;

export async function getPageModel(path: string, locale: string, sessionID: string) {
  const { log } = global.loggerInstance('ModelCall');
  // const locale = preData?.contexts?.global?.localeKey?.toLowerCase()?.replace('_', '-');
  locale = locale?.replace('_', '-');
  const pagePath = path.split('?')[0];

  const getCMSTemplateReqBody = {
    requestType: ROUTING_CONFIG,
    seoUrl: pagePath,
    localeKey: locale,
    experienceSegment: EXPERIENCE_SEGMENT,
    applicationName: APPLICATION_NAME,
    sessionToken: sessionID,
  };

  let pageModel: any = {};
  try {
    log.debug(`[STEP 5] NEXT_PUBLIC_DYNAMIC_ROUTING_URL: ${NEXT_PUBLIC_DYNAMIC_ROUTING_URL}`);
    log.debug(`[STEP 5] getCMSTemplateReqBody: ${JSON.stringify(getCMSTemplateReqBody)}`);
    const response = await axios.post(`${NEXT_PUBLIC_DYNAMIC_ROUTING_URL}`, getCMSTemplateReqBody);
    if (response) {
      log.debug(`[STEP 5] AEM model API call success with data: ${inspect(response?.data?.data)}`);
      pageModel = response?.data?.data;
    }
  } catch (err) {
    log.error(`[STEP 5] AEM Model API call failed: ${err}`);
    //returing page model and logging error
    return pageModel;
  }
  return pageModel;
}

export async function getSessionData(sessionIDVal: string) {
  let sessionData;
  let sessionHeader: any = {};
  const { log } = global.loggerInstance('SessionCall');
  const apiStartTime = new Date().getTime();
  try {
    log.debug(`[Session Data] This is the MI_SESSION_APP_URL that we are using: ${SESSION_APP_CALL_URL}`);
    log.debug(`[Session Data] This is the session token that we are using: ${sessionIDVal}`);
    const response = await axios.get(`${SESSION_APP_CALL_URL}`, {
      headers: {
        Cookie: `sessionID=${sessionIDVal}`,
      },
    });
    sessionHeader = response?.headers;
    sessionData = response?.data;
    log.debug(`[Session Data] Session Data Loaded, ${sessionData}`);
  } catch (e) {
    log.debug('[Session Data] Error with session route!', e);
  }
  log.debug('API call performance timing', apiStartTime, new Date().getTime());
  return { sessionData, sessionHeader };
}

export function getDataLayerScript(dataLayer: { data: any; mvpOffersData: string }) {
  return `var dataLayer = ${dataLayer?.data ? JSON.stringify(dataLayer?.data[0]) : '{}'}; var mvpOffers = ${
    dataLayer?.mvpOffersData && dataLayer?.mvpOffersData !== 'null' ? dataLayer?.mvpOffersData : '{}'
  };`;
}

export async function getFeatureFlagData() {
  const { log } = global.loggerInstance('MandatoryFee');
  try {
    log.debug(`API url: ${NEXT_PUBLIC_FEATURE_FLAG_URL}`);
    const response = await axios.get(`${NEXT_PUBLIC_FEATURE_FLAG_URL}`);
    log.debug('[Mandatory Fee] Mandatory Fee Loaded');
    return response.data;
  } catch (e) {
    log.debug(`[Mandatory Fee] Error with feature flag route!: ${e}`);
    return { status: 400, msg: 'Error!' };
  }
}

/**
 * method to handle redirects for configured error pages.
 * Keeping it centralized in case approach needs to change or if we
 * add additional error pages.
 * @returns {string} redirectUrl
 */
export const handleErrorPageRedirects = function () {
  const { log } = logger({})('HandleErrorPageRedirects');
  if (process.env['REDIRECT_URL']) {
    return addSubDirectoryPrefix(process.env['REDIRECT_URL']);
  } else {
    log.debug("process.env['REDIRECT_URL'] not available");
    return '/';
  }
};

/**
 * Method to update error message in session document. This method should be
 * used when we need to add a new error message in session document, which can
 * then be read to show the message in Aries Advanced Search Page.
 * @returns {object} sessionDoc
 */
export const updateSessionDocErrorMessages = async (
  sessionID: string,
  SESSION_APP_GET_CALL_URL: string,
  SESSION_APP_POST_CALL_URL: string,
  errorMessageKeys: string[]
) => {
  // TODO - build an endpoint generator and replace this URL with method call.
  const sessionUrl: string = SESSION_APP_GET_CALL_URL;
  const postSessionUrl: string = SESSION_APP_POST_CALL_URL;
  const removeBlock: any = [];

  try {
    const response = await axios.post(
      sessionUrl,
      {
        keys: SESSION_SEARCH_KEYS,
      },
      {
        headers: {
          Cookie: `sessionID=${sessionID}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    );
    const sessionDoc = response.data;
    const sessionDocCopy = JSON.parse(JSON.stringify(sessionDoc));

    const AriesCommonPayloadObject = sessionDoc?.cacheData?.data?.AriesCommon;
    const AriesSearchPayloadObject = sessionDoc?.cacheData?.data?.AriesSearch;
    const AriesRewardsPayloadObject = sessionDoc?.cacheData?.data?.AriesRewards;

    if (sessionDoc?.data?.AriesSearch) {
      // remove the searchCriteria, if searchCriteria is therethe searchform won't load on advance search page
      removeBlock?.push('AriesSearch.searchCriteria');
      AriesSearchPayloadObject.errorMessages = {};
      AriesSearchPayloadObject.errorMessages.submitSearchFormErrorMessages = { errorMessageKeys };
    }
    await axios.post(
      postSessionUrl,
      {
        createOrUpdate: {
          AriesSearch: AriesSearchPayloadObject,
          AriesCommon: AriesCommonPayloadObject,
          AriesRewards: AriesRewardsPayloadObject,
        },
        remove: removeBlock,
      },
      {
        headers: {
          Cookie: `sessionID=${sessionID}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Accept-Language': getLanguage() || NEXT_PUBLIC_DEFAULT_LANG || '',
        },
      }
    );
    // returning old copy of session data, which can be used to construce params
    // for redirection URL
    return sessionDocCopy.data;
  } catch (error) {
    return error;
  }
};

/**
 * Method to form and return search page query URL
 * @param sessionData {object}
 * @param errorCode {string}
 * @returns {string} searchPageQueryParams
 */
export function formAdvanceSearchPageQuery({
  sessionData,
  errorCode = '',
  addErrorParams = false,
}: {
  sessionData: any;
  errorCode?: string;
  addErrorParams?: boolean;
}): string {
  return `roomTypeCode=&recordsPerPage=40&autoSuggestItemType=&destinationAddress.types=&destinationAddress.latitude=${
    sessionData?.AriesSearch?.searchCriteria?.address?.latitude
  }&propertyCode=&destinationAddress.stateProvinceShort=&isInternalSearch=true&destinationAddress.cityPopulation=&vsInitialRequest=false&searchType=${
    sessionData?.AriesSearch?.searchCriteria?.searchType
  }&destinationAddress.locality=&showAddressPin=&destinationAddress.stateProvinceDisplayName=&destinationAddress.destinationPageDestinationAddress=&countryName=&destinationAddress.stateProvince=${
    sessionData?.AriesSearch?.searchCriteria?.address?.stateProvince
  }&searchRadius=${sessionData?.AriesSearch?.searchCriteria?.searchRadius}&singleSearchAutoSuggest=${
    sessionData?.AriesSearch?.searchCriteria?.singleSearchAutoSuggest
  }&destinationAddress.placeId=${
    sessionData?.AriesSearch?.searchCriteria?.destinationAddressPlaceId
  }&airportName=&for-hotels-nearme=Near&suggestionsPropertyCode=&destinationAddress.country=${
    sessionData?.AriesSearch?.searchCriteria?.address?.country
  }&destinationAddress.name=&poiCity=&destinationAddress.countryShort=&poiName=&destinationAddress.address=${
    sessionData?.AriesSearch?.searchCriteria?.address?.destination
  }&search-countryRegion=&collapseAccordian=is-hidden&singleSearch=true&destinationAddress.cityPopulationDensity=&destinationAddress.secondaryText=${
    sessionData?.AriesSearch?.searchCriteria?.destinationAddressSecondaryText
  }&destinationAddress.postalCode=&destinationAddress.city=${
    sessionData?.AriesSearch?.searchCriteria?.address?.city
  }&destinationAddress.mainText=${
    sessionData?.AriesSearch?.searchCriteria?.destinationAddressMainText
  }&airportCode=&isTransient=true&destinationAddress.longitude=${
    sessionData?.AriesSearch?.searchCriteria?.address?.longitude
  }&initialRequest=false&destinationAddress.website=&search-locality=&roomTypeCode=&propertyCode=&flexibleDateSearchRateDisplay=false&propertyName=&isSearch=true&marriottRewardsNumber=&isRateCalendar=false&incentiveType_Number=&incentiveType=&flexibleDateLowestRateMonth=&flexibleDateLowestRateDate=&isMultiRateSearch=&multiRateMaxCount=&multiRateCorpCodes=&useMultiRateRewardsPoints=&multiRateClusterCodes=&multiRateCorpCodesEntered=&lowestRegularRate=&js-location-nearme-values=&destinationAddress.destination=${
    sessionData?.AriesSearch?.searchCriteria?.address?.destination
  }&fromToDate=&fromToDate_submit=${moment(
    sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkOutDate
  )?.format('MM/DD/YYYY')}&fromDate=${moment(
    sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkInDate
  )?.format('MM/DD/YYYY')}&toDate=${moment(
    sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkOutDate
  )?.format('MM/DD/YYYY')}&toDateDefaultFormat=${moment(
    sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkOutDate
  )?.format('MM/DD/YYYY')}&fromDateDefaultFormat=${moment(
    sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkInDate
  )?.format('MM/DD/YYYY')}&flexibleDateSearch=${
    sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.flexibleDate
  }&isHideFlexibleDateCalendar=${
    sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.hideFlexibleDateCalendar
  }&t-start=${moment(sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkInDate)?.format(
    'YYYY-MM-DD'
  )}&t-end=${moment(sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.checkOutDate)?.format(
    'YYYY-MM-DD'
  )}&lengthOfStay=${sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.lengthOfStay}&roomCountBox=${
    sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.numRooms
  } Room&roomCount=${sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.numRooms}&guestCountBox=${
    sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.numAdultsPerRoom
  } Adult Per Room&numAdultsPerRoom=${
    sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.numAdultsPerRoom
  }&childrenCountBox=${
    sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.numChildrenPerRoom
  } Children Per Room&childrenCount=${
    sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.numChildrenPerRoom
  }&childrenAges=${sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.childrenAges?.join(
    ','
  )}&clusterCode=${sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.clusterCode}&corporateCode=${
    sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.corporateCode
  }&useRewardsPoints=${sessionData?.AriesSearch?.searchCriteria?.availabilityRequestVO?.rewardsRedemption}${
    addErrorParams
      ? `&useRequestCriteria=true&showErrors=true${errorCode ? '&availabilityErrorCode=' + errorCode : ''}`
      : ''
  }`;
}
export const changeCountryCodeForChina = (countryCode: string) => {
  if (countryCode === 'HK' || countryCode === 'MO' || countryCode === 'TW') {
    return 'CN';
  } else {
    return countryCode;
  }
};
/**
 * This function will fetch mock modal from Local for find hotel page in local development mode.
 * @returns {object} mockmodal
 */
export const getMockPageModel = async (page: string) => {
  const { log } = global.loggerInstance('MockModelCall');
  try {
    const response =
      page === 'HQV'
        ? await import('./_localDevMocks/hqvMockModel').then(data => {
            return data?.HQVModel;
          })
        : await import('./_localDevMocks/findHotelMock').then(data => {
            return data?.FindHotelModel;
          });
    return response;
  } catch (err) {
    log.error(`Mock model loading failed: ${err}`);
    return null;
  }
};

// /**
//  * This function will fetch mock modal from AEM for find hotel page in local development mode.
//  * @returns {object} mockmodal
//  */
// export const getMockPageModel = async () => {
//   const { log } = global.loggerInstance('MockModelCall');
//   try {
//     const response = await axios.get('https://marriott-sites-dev.adobecqms.net/etc.clientlibs/mi-aem-shop/clientlibs/clientlib-mock-model.min.js');
//     return response?.data;
//   } catch (err) {
//     log.error(`Mock model loading failed: ${err}`);
//   }
// };

/**
 * This function will fetch mock session data from local in local development mode.
 * @returns {object} sessionmock
 */
export const getMockSessionData = async () => {
  const { log } = global.loggerInstance('MockSessionDataCall');
  try {
    const response = await import('./_localDevMocks/sessionData').then(data => {
      return data?.mockSessionData;
    });
    return response;
  } catch (err) {
    log.error(`Mock sessionData loading failed: ${err}`);
    return null;
  }
};

//TODO: replace URL once AEM team team share it.
// /**
//  * This function will fetch mock session data from AEM in local development mode.
//  * @returns {object} sessionmock
//  */
// export const getMockSessionData = async () => {
//   const { log } = global.loggerInstance('MockSessionDataCall');
//   try {
//     const response = await axios.get('https://marriott-sites-dev.adobecqms.net/etc.clientlibs/mi-aem-shop/clientlibs/clientlib-mock-model.min.js');
//     return response?.data;
//   } catch (err) {
//     log.error(`Mock sessionData loading failed: ${err}`);
//   }
// };

/**
 * This function will fetch mock search result data from local for search results in local development mode.
 * @returns {object} mockmodal
 */
export const getMockSearchResultData = async () => {
  const { log } = global.loggerInstance('MockSearchResultDataCall');
  try {
    const response = await import('./_localDevMocks/mockSearchResultData').then(data => {
      return data?.SearchResultMockData;
    });
    return response;
  } catch (err) {
    log.error(err);
    return null;
  }
};

/**
 * This function will fetch HQV modal Data.
 * @returns {object} HQVmodal
 */
export const getHQVPageModel = async (path: string, locale: string, templateURL: string, sessionID: string) => {
  const getCMSTemplateReqBody = {
    requestType: ROUTING_CONFIG,
    seoUrl: path,
    localeKey: locale,
    experienceSegment: EXPERIENCE_SEGMENT,
    applicationName: APPLICATION_NAME,
    sessionToken: sessionID,
  };
  let pageModel = {};
  try {
    const response = await axios.post(`${templateURL}`, getCMSTemplateReqBody);
    if (response) {
      pageModel = response?.data?.data;
    } else {
      pageModel = {};
    }
  } catch (err) {
    // log.error(`HQV_MODEL_CALL : API call failed: ${err}`);
    //returing page model and logging error
    return pageModel;
  }
  return pageModel;
};

//converts ":items" format to "cqItems" format.
export function transformToCQ(propKey: string) {
  const tempKey = propKey.substring(1);

  return 'cq' + tempKey.substring(0, 1).toUpperCase() + tempKey.substring(1);
}

//To format mock model in local dev, in other envs this formatting is taken care by OOTB fetchModel utility
export const respGridUtil = (item: any) => {
  if (!item || !Object.keys(item).length) {
    return { cqPath: '' };
  }

  const keys = Object.getOwnPropertyNames(item);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props: any = {};

  keys.forEach((key: string) => {
    const propKey = key.startsWith(':') ? transformToCQ(key) : key;
    props[propKey] = item[key] || '';
  });

  return props;
};
export { getLanguage, addSubDirectoryPrefix };

//This Function determines to disbale hyperlink and reviewcount
export const isReviewCountHidden = (currentLocale: string) => {
  const localWithReviewDisable = [LOCALE_ZH_CN];
  return localWithReviewDisable.includes(currentLocale);
};

export const getRateConversionFactor = (
  currentConverSionRateArr: {
    currency: string;
    currentConversionRate: number;
  }[],
  currency: string
) => {
  const currentConverSionRate = currentConverSionRateArr?.find(
    (key: { currency: string }) => key.currency === currency
  );
  return currentConverSionRate?.currentConversionRate ?? 1;
};

export const capitalize = (text: string) => {
  return `${text?.charAt(0)?.toUpperCase()}${text?.slice(1)?.toLowerCase()}`;
};

export async function updateSessionInSearchForm(
  sessionData: any,
  SESSION_APP_POST_CALL_URL: string,
  NEXT_PUBLIC_PREFIX: string,
  singleDateLimit: Date | string | Moment,
  groupDateLimit: Date | string | Moment
) {
  // Define URLs and variables
  const postSessionUrl: any = SESSION_APP_POST_CALL_URL;
  const removeBlock: any = [];
  try {
    // Extract session data from the response
    const AriesCommonPayloadObject = sessionData?.cacheData?.data?.AriesCommon
      ? {
          ...sessionData?.cacheData?.data?.AriesCommon,
          singleDateLimit,
          groupDateLimit,
        }
      : { singleDateLimit, groupDateLimit };

    const AriesUpdateSessionPayloadObject = {
      AriesCommon: AriesCommonPayloadObject,
    };

    // Send a POST request to update the session
    await axios.post(
      postSessionUrl,
      {
        createOrUpdate: AriesUpdateSessionPayloadObject,
        remove: removeBlock,
      },
      {
        headers: {
          Cookie: `sessionID=${sessionData?.sessionToken}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (error) {
    // Handle and return any errors
    const loggerApiURL = getLoggerApiURL(NEXT_PUBLIC_PREFIX);
    axios.post(loggerApiURL, {
      message: `Error while making session call with sessionId ${sessionData?.sessionToken}: ${JSON.stringify(error)}`,
    });
  }
}

// function to get the logger api url
export const getLoggerApiURL = (NEXT_PUBLIC_PREFIX: string) => {
  let loggerApiURL = '/api/logger';
  if (getDeployedEnvType() === 'higher') {
    loggerApiURL = `${NEXT_PUBLIC_PREFIX}/api/logger`;
  }

  return loggerApiURL;
};

//Function to return the translated values of specified currency
export const fetchCurrencyToLocale = (currentLocale: string, currencyLabel: string, currency?: string) => {
  switch (currentLocale) {
    case JP_LOCALE:
      return currency === 'JPY' ? currencyLabel : currency;
    case KR_LOCALE:
      return currency === 'KRW' ? currencyLabel : currency;
    default:
      return currency;
  }
};

//this function will convert all the Arabic number to english numbers
export const convertArabicToEnglishNumbers = (dateString: string): string => {
  // Arabic to English numeral mapping
  const arabicToEnglishMap: { [index: string]: string } = {
    '٠': '0',
    '١': '1',
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
  };

  // Replace Arabic numerals with English numerals
  return dateString.replace(/[٠-٩]/g, function (match) {
    return arabicToEnglishMap[match].toString();
  });
};
