/* eslint-disable @typescript-eslint/no-explicit-any */
import { Model } from '@adobe/aem-spa-page-model-manager';
import { ModelProps } from '@adobe/aem-react-editable-components';
import { KEY_BOARD_EVENTS, TrackingPropsInterface } from './enums/enums';

export const canUseDOM = !!(typeof window !== 'undefined' && window.document);

//Utility for converting nested container json to tree format

export type AEMModelType = Model & ModelProps;

export type StringIndexedObject = {
  [key: string]: any;
};

export interface ScrollSettings {
  isNavComponent?: boolean;
  isStickyOnScrollDown?: boolean;
  isStickyOnScrollUp?: boolean;
}

export interface ScrollStoreModel extends Model {
  componentId?: string;
  scrollSetting?: ScrollSettings;
  ':children'?: {
    [key: string]: ScrollStoreModel;
  };
  ':items'?: {
    [key: string]: ScrollStoreModel;
  };
}

export interface AEMModel extends AEMModelType {
  resourceProperties?: any;
  itemKey?: string;
  backgroundMedia?: any;
  trackingProperties?: TrackingPropsInterface;
  componentId?: string;
  scrollSetting?: ScrollSettings;
  ':children'?: {
    [key: string]: ScrollStoreModel;
  };
  ':items'?: {
    [key: string]: ScrollStoreModel;
  };
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SortedModelArray extends Array<AEMModel> {}
export interface AEMContainerProps {
  cqPath?: string;
  isAuthorMode?: boolean;
}

export const sortAEMModelItemsByOrder = (data: AEMModel): SortedModelArray => {
  if (!data) return [];
  const itemsOrder = data[':itemsOrder'] || data?.cqItemsOrder || [];
  const items = data[':items'] || data?.cqItems || {};
  return itemsOrder?.length
    ? itemsOrder.map((itemKey: string) => {
        return { ...items[itemKey], itemKey };
      })
    : [];
};

//TODO:Importing from headless utils is giving error.So kept here for now. Need to remove after the issue is fixed.
export function transformToCQ(propKey: string) {
  const tempKey = propKey.substring(1);

  return 'cq' + tempKey.substring(0, 1).toUpperCase() + tempKey.substring(1);
}

export const respGridUtil = (item: any) => {
  if (!item || !Object.keys(item).length) {
    return { cqPath: '' };
  }

  const keys = Object.getOwnPropertyNames(item);
  const props: any = {};

  keys.forEach((key: string) => {
    const propKey = key.startsWith(':') ? transformToCQ(key) : key;
    props[propKey] = item[key] || '';
  });

  return props;
};

/**
 *
 * @param text
 * Remove the parent tag from RTE text
 * <p>Lorem Ipsum has been the <sub>industry's standard</sub> dummy text ever</p>
 * =>Lorem Ipsum has been the <sub>industry's standard</sub> dummy text ever
 */
export const removeParentTagRTE = (textRTE: string): string => {
  return textRTE.substring(textRTE.indexOf('>') + 1, textRTE.lastIndexOf('<'));
};

export const getEventKeyTypes = (e: React.KeyboardEvent<HTMLElement>) => {
  const { ENTER, TAB, ESCAPE, ARROW_UP, ARROW_DOWN, ARROW_RIGHT, ARROW_LEFT, SPACEBAR } = KEY_BOARD_EVENTS ?? {};
  return {
    isEnterKey: e.key === ENTER?.key || e.keyCode === ENTER?.keyCode,
    isTabKey: e.key === TAB?.key || e.keyCode === TAB?.keyCode,
    isEscapseKey: e.key === ESCAPE?.key || e.keyCode === ESCAPE?.keyCode,
    isUpArrowKey: e.key === ARROW_UP?.key || e.keyCode === ARROW_UP.keyCode,
    isDownArrowKey: e.key === ARROW_DOWN?.key || e.keyCode === ARROW_DOWN?.keyCode,
    isRightArrowKey: e.key === ARROW_RIGHT?.key || e.keyCode === ARROW_RIGHT?.keyCode,
    isLeftArrowKey: e.key === ARROW_LEFT?.key || e.keyCode === ARROW_LEFT?.keyCode,
    isSpaceBarKey: e.key === SPACEBAR?.key || e.keyCode === SPACEBAR?.keyCode,
  };
};

/**
 *
 * Throttling any event handler to avoid excessive function calls
 * @param func @param delay
 * @returns the handler function "func" which executes after every 500 ms
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const throttle = (func: any, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  let lastExecTime = 0;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - lastExecTime;

    if (!timeoutId || elapsedTime >= delay) {
      func.apply(this, args);
      lastExecTime = currentTime;
    }

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      lastExecTime = new Date().getTime();
    }, delay);
  };
};
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/**
 * This function updates the keys of an object based on a provided key mapping.
 *
 * @param {StringIndexedObject} aemModelObj - The original object whose keys are to be updated.
 *                                    It must be a non-null object.
 *
 * @throws {Error} Will throw an error if the provided obj or keyMap is not an object or is null.
 *
 * @returns {StringIndexedObject} A new object with updated keys.
 *                                If a key in the original object is found in the keyMap,
 *                                it will be replaced with the corresponding value from the keyMap.
 *                                If a key in the original object is not found in the keyMap,
 *                                it will remain the same.
 *                                Note: The function does not mutate the original object.
 */
export const updateAEMCQKeys = (aemModelObj: StringIndexedObject): StringIndexedObject => {
  const cqKeyMap: { [key: string]: string } = {
    ':items': 'cqItems',
    ':itemsOrder': 'cqItemsOrder',
    ':type': 'cqType',
  };

  if (typeof aemModelObj !== 'object' || aemModelObj === null) {
    throw new Error('Invalid object provided');
  }

  const updatedObject: StringIndexedObject = { ...aemModelObj };

  for (const key in cqKeyMap) {
    if (Object.prototype.hasOwnProperty.call(aemModelObj, key)) {
      const updatedKey = cqKeyMap[key];
      // Only update if updatedKey is defined and does not exist in the original object
      if (updatedKey && !Object.prototype.hasOwnProperty.call(aemModelObj, updatedKey)) {
        updatedObject[updatedKey] = aemModelObj[key];
        delete updatedObject[key];
      }
    }
  }

  return updatedObject;
};

export const getLoyaltyTheme = (inputString: string | undefined) => {
  const regex = /\b(memberSolid|memberSolidAlt|silverSolid|goldSolid|platinumSolid|titaniumSolid|ambassadorSolid)\b/g;
  const matches = inputString?.match(regex);

  if (matches) {
    const key = matches[0];
    return key;
  } else {
    return;
  }
};
