/* eslint-disable @typescript-eslint/no-explicit-any */

import { createContext, useContext } from 'react';

export const NavigationContext = createContext<{
  offset: number;
  navRef: any;
  isInverse: boolean;
}>({ offset: 0, navRef: null, isInverse: false });

export const useNavContext = () => {
  return useContext(NavigationContext);
};
