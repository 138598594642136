export const constants = {
  DEFAULT_LANG: 'en_US',
  DEFAULT_COUNTRY: 'US',
  ITEM_PATH_ROOT: 'root/responsivegrid',
  ITEM_PATH_HEADER: 'root/header-responsivegrid',
  ITEM_PATH_FOOTER: 'root/footer-responsivegrid',
  PAGE_LOADED: 'Page Loaded',
  PAGE_LOADING: 'Page Loading',
  ROUTING_CONFIG: 'routingConfig',
  APPLICATION_NAME: 'shop',
  EXPERIENCE_SEGMENT: ['NOT Logged In'],
  OFFER_IMAGE_CACHE_DOMAIN: 'https://cache.marriott.com',
  INDEX_FOLLOW: 'index,follow',
  NO_INDEX_NO_FOLLOW: 'noindex,nofollow',
  NO_INDEX_FOLLOW: 'noindex,follow',
  MARRIOTT_DEALS: 'marriottDeals',
  REQUIRED_ASPECT_RATIO: 'CLASSIC',
  ASSET_VARIATION_ICON: 'iconfont',
  IMPERIAL: 'imperial',
  CAMPAIGN_ACTIVE: 'active',
  CAMPAIGN_INACTIVE: 'inactive',
};

export const LOCALE_LANGCODE_MAPPING = {
  'it-IT': 'it_IT',
  'ru-RU': 'ru_RU',
  'en-GB': 'en_GB',
  'ko-KR': 'ko_KR',
  'en-US': 'en_US',
  'pt-BR': 'pt_BR',
  'zh-CN': 'zh_CN',
  'fr-FR': 'fr_FR',
  'ja-JP': 'ja_JP',
  'de-DE': 'de_DE',
  'pt-PT': 'pt_PT',
  'es-ES': 'es_ES',
};

export const LOCALE_DOMAIN_MAPPING = {
  it_IT: { domain: '.it', hreflang: 'it-IT' },
  ru_RU: { domain: '.com.ru', hreflang: 'ru-RU' },
  en_GB: { domain: '.co.uk', hreflang: 'en-GB' },
  ko_KR: { domain: '.co.kr', hreflang: 'ko-KR' },
  en_US: { domain: '.com', hreflang: 'en-US' },
  pt_BR: { domain: '.com.br', hreflang: 'pt-BR' },
  zh_CN: { domain: '.com.cn', hreflang: 'zh-CN' },
  fr_FR: { domain: '.fr', hreflang: 'fr-FR' },
  ja_JP: { domain: '.co.jp', hreflang: 'ja-JP' },
  de_DE: { domain: '.de', hreflang: 'de-DE' },
  pt_PT: { domain: '.pt', hreflang: 'pt-PT' },
  es_ES: { domain: '.com', hreflang: 'es' },
};

export const LOCALE_SPECIFIC_MAPPING = {
  en_US: { url: 'https://www.marriott.com', hreflang: 'en-US' },
  it_IT: { url: 'https://www.marriott.com/it', hreflang: 'it-IT' },
  ru_RU: { url: 'https://www.marriott.com/ru', hreflang: 'ru-RU' },
  en_GB: { url: 'https://www.marriott.com/en-gb', hreflang: 'en-GB' },
  ko_KR: { url: 'https://www.marriott.com/ko', hreflang: 'ko-KR' },
  pt_BR: { url: 'https://www.marriott.com/pt-br', hreflang: 'pt-BR' },
  zh_CN: { url: 'https://www.marriott.com.cn', hreflang: 'zh-CN' },
  fr_FR: { url: 'https://www.marriott.com/fr', hreflang: 'fr-FR' },
  ja_JP: { url: 'https://www.marriott.com/ja', hreflang: 'ja-JP' },
  de_DE: { url: 'https://www.marriott.com/de', hreflang: 'de-DE' },
  pt_PT: { url: 'https://www.marriott.com/pt-br', hreflang: 'pt-PT' },
  es_ES: { url: 'https://www.marriott.com/es', hreflang: 'es' },
};

export const OFFERS_UXL_CLIENT_NAME = 'phoenix_offers';
export const OFFERS_UXL_CLIENT_VERSION = 'V1';
export const BREAKPOINT_TABLET = 767;
export const BREAKPOINT_DESKTOP = 991;
export const DATE_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';
//commenting details_logger. Since there is build error as m is declared but it's value never read
// export const DETAILS_LOGGER = {
//   debug: (m: string): void => console.log(''), //eslint-disable-line no-console
//   error: (m: string): void => console.log(''), //eslint-disable-line no-console
//   info: (m: string): void => console.log(''), //eslint-disable-line no-console
// };

export const ADD_BUDDHIST_YEAR_VALUE = 543;
export const KO_KR_LOCALE = 'kr';
export const TH_TH_LOCALE = 'th';
export const MOCK_SESSION_ID = '7FC3A108-DF69-5E10-B767-6D2FB461B924';
export const IMAGE_CACHE_DOMAIN = 'https://cache.marriott.com';
export const RZ_HOTEL_DOMAIN = 'https://www.ritzcarlton.com';

export const LOCALE_SUBDIRECTORY_MAPPING = {
  'it-IT': '/it',
  'ru-RU': '/ru',
  'en-GB': '/en-gb',
  'ko-KR': '/ko',
  'en-US': '',
  'pt-BR': '/pt-br',
  'zh-CN': '',
  'fr-FR': '/fr',
  'ja-JP': '/ja',
  'de-DE': '/de',
  'pt-PT': '/pt',
  'es-ES': '/es',
};

export const MONTHSHORTVALUE = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const WEEKSDAYSSHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const WEEKDAYSLONGNAME = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const INTERNAL_CTA_TYPE = 'Internal';
export const EXTERNAL_CTA_TYPE = 'External';

export const errorTypes = ['invalidDealMsg', 'invalidUserMsg', 'invalidPreviewOffer', 'notEligible'];

export const PRIMARY_MEDIA = 'primary';
export const SECONDARY_MEDIA = 'secondary';

export const ASPECT_RATIO_UXL_FIELD_MAPPING = {
  'Wide-Hor': 'wideHorizontal',
  'Pano-Hor': 'panoramicHorizontal',
  'Pano-Ver': 'panoramicVertical',
  'Wide-Ver': 'wideVertical',
  'Feature-Hor': 'featureHorizontal',
  'Feature-Ver': 'featureVertical',
  'Classic-Hor': 'classicHorizontal',
  'Classic-Ver': 'classicVertical',
  Square: 'square',
};
export const ASSET_VARIATION = {
  IMAGE: 'image',
};

export const NEXT_PUBLIC_ENV_KEYS = [
  'NEXT_PUBLIC_SUBMIT_OSERP_URL',
  'NEXT_PUBLIC_SUBMIT_SEARCH_URL',
  'SESSION_GET_CALL_URL_CLIENT',
  'NEXT_PUBLIC_AVAILABILITY_SEARCH_URL',
];
export const HOTEL_VIEW = 'hotelView';
export const BREAKPOINT_LARGER_MOBILE = 650;
export const BREAKPOINT_DESKTOP_1023 = 1023;
export const ALLOWED_TAGS_IN_DESCRIPTION = [
  'b',
  'strong',
  'i',
  'em',
  'u',
  's',
  'del',
  'sub',
  'sup',
  'mark',
  'small',
  'big',
  'ul',
  'ol',
  'li',
  'dl',
  'dt',
  'dd',
  'table',
  'tr',
  'td',
  'th',
  'thead',
  'tbody',
  'tfoot',
  'colgroup',
  'col',
  'caption',
  'p',
  'br',
  'hr',
  'blockquote',
  'pre',
  'a',
  'code',
  'span',
  'div',
  'img',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
];
export const PANO_HOR_IMG = 'Pano-Hor';
export const WIDE_HOR_IMG = 'Wide-Hor';
export const SQUARE_IMG = 'Square';
export const PARAM_PAGENO = 'pg';
