/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { AEMReactCompMap, TabContainer as TabContainerMolecule, updateAEMCQKeys } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { FC, lazy, ReactElement, Suspense, useEffect, useState } from 'react';
import { TabProps, TabContainerProps, TabDetails } from './TabContainer.types';

export const TabContainerConfig = {
  emptyLabel: 'TabContainer',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/tabcontainer`,
};

export const TabContainerComp: FC<TabProps> = (props: TabProps) => {
  const {
    header,
    headerTag,
    subHeader,
    trackingProperties,
    styleclass,
    componentId,
    pagePath,
    itemPath,
    isAuthorMode,
    contentBlockAlignment,
    headerFontSize,
    subFontSize,
    subHeaderTag,
    descriptionText,
    descFontSize,
    tabDetails,
    layoutContainer,
  } = props;

  const updatedProps = updateAEMCQKeys(props);
  const cqItems = updatedProps['cqItems'];

  const mapper = AEMReactCompMap(props?.allowedComponents);

  const [isEditMode, setEditMode] = useState(false);

  useEffect(() => {
    const pageWcmMode = document.getElementsByTagName('html')[0];
    const updateStyleComponent = document.getElementById(`${componentId}__slides`);
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class') {
          const currWcmMode = pageWcmMode?.getAttribute('class');
          if (currWcmMode?.includes('Edit')) {
            updateStyleComponent?.classList.add('glide__slides_authoring');
            setEditMode(true);
          } else if (currWcmMode?.includes('Preview')) {
            updateStyleComponent?.classList.remove('glide__slides_authoring');
            setEditMode(false);
          }
        }
      });
    });
    observer.observe(pageWcmMode, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  // This function is used to render the component in authoring mode authorComponentWrapper
  const authorComponentWrapper = (index: number) => {
    return (
      <ResponsiveGrid
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pagePath={pagePath}
        itemPath={`${itemPath}/${tabDetails[index].nodeName}`}
        columnCount="12"
        gridClassNames={''}
        customClassName={''}
        config={{
          isEmpty: () => true,
          resourceType: 'mi-aem-common-spa/components/container',
        }}
      />
    );
  };

  // This function is used to render the component in end-user mode
  const publishComponentWrapper = (jsonData: any, idx: number): ReactElement | null => {
    delete jsonData.tabDetails;
    // eslint-disable-next-line no-prototype-builtins
    if (jsonData?.hasOwnProperty(`${tabDetails[idx].nodeName}`)) {
      const page = jsonData[`${tabDetails[idx].nodeName}`];
      const pageItems = page[':items'];
      for (const itemKey in pageItems) {
        if (Object.prototype.hasOwnProperty.call(pageItems, itemKey)) {
          const item = pageItems[itemKey];
          const key = item?.componentId;
          const itemType = item[':type']?.split('/').pop();
          if (Object.prototype.hasOwnProperty.call(mapper, itemType)) {
            const innerComp = mapper[itemType];
            const Component = lazy(() =>
              import(`../${innerComp}/index`).then(module => ({
                default: module[`${innerComp}`],
              }))
            );
            const itemProps = { model: { ...item }, ...item, componentPath: props?.componentPath };
            return (
              <div key={key}>
                <Suspense fallback={<></>}>
                  <Component {...itemProps} />
                </Suspense>
              </div>
            );
          }
          return null;
        }
      }
    }
    return null;
  };

  return (
    <TabContainerMolecule
      componentId={componentId}
      subHeader={subHeader}
      styleclass={styleclass}
      trackingProperties={trackingProperties}
      header={header}
      headerTag={headerTag}
      isAuthorMode={isAuthorMode}
      contentBlockAlignment={contentBlockAlignment}
      headerFontSize={headerFontSize}
      subFontSize={subFontSize}
      subHeaderTag={subHeaderTag}
      descriptionText={descriptionText}
      descFontSize={descFontSize}
      tabDetails={tabDetails}
      layoutContainer={layoutContainer}
      isEditMode={isEditMode}
      componentPath={props?.componentPath}
      flexibleTabWidth={props?.flexibleTabWidth}
      tabsPerViewInDesktop={props?.tabsPerViewInDesktop}
    >
      {isAuthorMode && Array.from({ length: tabDetails?.length }, (_, i) => authorComponentWrapper(i))}
      {!isAuthorMode && tabDetails?.map((_tab: TabDetails, idx) => publishComponentWrapper(cqItems, idx))}
    </TabContainerMolecule>
  );
};

export const TabEditableComponent = (props: any) => {
  const { model } = props;
  const cqType = model?.cqType || model[':type'];
  return (
    <EditableComponent config={TabContainerConfig} {...props}>
      <TabContainerComp {...props} componentName={cqType?.split('/')?.pop()} />
    </EditableComponent>
  );
};

export const TabContainer: FC<TabContainerProps> = (props: TabContainerProps) => {
  return (
    <div className={clsx(props.model.styleclass)} data-testid="tab-container">
      <TabEditableComponent {...props} />
    </div>
  );
};
