import dynamic from 'next/dynamic';
import { StaticWrapper } from './organisms/StaticWrapper';
import { CardCarouselContainer } from './organisms/CardCarouselContainer';
import { LinkList } from './organisms/LinkList';
import { AccordionContainerEnhanced } from './organisms/AccordionContainerEnhanced';
import { BrandRibbon } from './organisms/BrandRibbon';
import { Hero } from './organisms/Hero';
import { LinkBanner, LinkBannerDynamic } from './organisms/LinkBanner';
import { GeneralMessage } from './organisms/GeneralMessage';
import { Mosaic } from './organisms/Mosaic';
import { BannerText } from './organisms/BannerText';
import { CarouselGallery } from './organisms/CarouselGallery';
import { DataBlock } from './organisms/DataBlock';
import { NavigationMockModel } from './molecules/Navigation';
import { SignInDrawer, MockGetUserDetailsAuthenticated, MockGetUserDetailsRemembered } from './molecules/SignInDrawer';
import { Footer } from './molecules/Footer';
import { MiniCardCarouselContainer } from './organisms/MiniCardCarouselContainer';
import { Text } from './organisms/Text';
import { BannerFeature } from './organisms/BannerFeature';
import { TabContainer } from './organisms/TabContainer';
import { CardSummary } from './organisms/CardSummary';
import { Media } from './organisms/Media/Media';

const EditableHTLWrapper = dynamic(() => import('./organisms/EditableHTLWrapper').then(mod => mod.EditableHTLWrapper));
const CardHorizontalMini = dynamic(() => import('./organisms/CardHorizontalMini').then(mod => mod.CardHorizontalMini));
const CobrandCardHorizontalFlexible = dynamic(() =>
  import('./organisms/CobrandCardHorizontalFlexible').then(mod => mod.CobrandCardHorizontalFlexible)
);
const CardHorizontalFeature = dynamic(() =>
  import('./organisms/CardHorizontalFeature').then(mod => mod.CardHorizontalFeature)
);
const BannerLayered = dynamic(() => import('./organisms/BannerLayered').then(mod => mod.BannerLayered));
const AccordionContainer = dynamic(() => import('./organisms/AccordionContainer').then(mod => mod.AccordionContainer));
const CardLayered = dynamic(() => import('./organisms/CardLayered').then(mod => mod.CardLayered));
const CardVertical = dynamic(() => import('./organisms/CardVertical').then(mod => mod.CardVertical));
const RichText = dynamic(() => import('./organisms/RichText').then(mod => mod.RichText));
const IconBlock = dynamic(() => import('./organisms/IconBlock').then(mod => mod.IconBlock));
const VariableColumnContainer = dynamic(() =>
  import('./organisms/VariableColumnContainer').then(mod => mod.VariableColumnContainer)
);
const NavigationContainerWrapper = dynamic(() =>
  import('./organisms/NavigationContainer').then(mod => mod.NavigationContainerWrapper)
);
const NavigationUtilityLinkWrapper = dynamic(() =>
  import('./molecules/Navigation').then(mod => mod.NavigationUtilityLinkWrapper)
);
const NavigationWrapper = dynamic(() => import('./molecules/Navigation').then(mod => mod.NavigationWrapper));

export {
  CardLayered,
  CardHorizontalMini,
  EditableHTLWrapper,
  StaticWrapper,
  CardCarouselContainer,
  CardVertical,
  CardHorizontalFeature,
  NavigationContainerWrapper,
  BannerLayered,
  BrandRibbon,
  IconBlock,
  LinkList,
  AccordionContainer,
  AccordionContainerEnhanced,
  CobrandCardHorizontalFlexible,
  Hero,
  RichText,
  LinkBanner,
  LinkBannerDynamic,
  GeneralMessage,
  Mosaic,
  BannerText,
  VariableColumnContainer,
  CarouselGallery,
  DataBlock,
  NavigationWrapper,
  NavigationUtilityLinkWrapper,
  NavigationMockModel,
  SignInDrawer,
  MockGetUserDetailsAuthenticated,
  MockGetUserDetailsRemembered,
  Footer,
  MiniCardCarouselContainer,
  Text,
  BannerFeature,
  TabContainer,
  CardSummary,
  Media,
};

export { getClickTrackValue } from './utils/TrackingPropertyUtils';
export { DEFAULT_TRACKING_VALUES } from './utils/constants';
