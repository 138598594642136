import React, { ChangeEvent, FC, useEffect, useRef } from 'react';
import { RichTextProps } from './RichText.types';
import { StyledRichText } from './RichText.styles';
import clsx from 'clsx';

export const RichText: FC<RichTextProps> = props => {
  const { text, componentId, styleclass, customClass, contentEditable, onChange, ...customProps } = props;
  const editableDivRef = useRef<HTMLDivElement>(null);

  // Function to update aria-labels on <a> tags based on parent span's class names
  const updateAnchorAriaLabels = () => {
    if (!text) return; // Exit early if text is null or empty

    // Handle <span> elements
    editableDivRef.current?.querySelectorAll('span').forEach(span => {
      let anchorTag = span.querySelector('a'); // Find <a> tag within <span>

      // Check if <span> is inside an <a> if no <a> is found within
      if (!anchorTag && span.parentElement?.tagName === 'A') {
        anchorTag = span.parentElement as HTMLAnchorElement;
      }

      if (anchorTag) {
        if (span.classList.contains('m-link-tertiary-button-external')) {
          anchorTag.setAttribute('aria-label', `${anchorTag.textContent} Clickable link Opens in new tab / window`);
        } else if (span.classList.contains('m-link-action')) {
          anchorTag.setAttribute('aria-label', `${anchorTag.textContent} Clickable link`);
        } else {
          anchorTag.removeAttribute('aria-label'); // Remove aria-label if class doesn't match
        }
      }
    });

    // Handle <a> tags not inside <span>
    editableDivRef.current?.querySelectorAll('a').forEach(anchorTag => {
      // Skip <a> tags that are inside a <span> (already handled above)
      if (anchorTag.closest('span')) return;

      // Check the target attribute and set appropriate aria-label
      const target = anchorTag.getAttribute('target');
      if (target === '_blank') {
        anchorTag.setAttribute('aria-label', `${anchorTag.textContent} Clickable link Opens in new tab / window`);
      } else if (target === '_self') {
        anchorTag.setAttribute('aria-label', `${anchorTag.textContent} Clickable link`);
      } else {
        anchorTag.removeAttribute('aria-label'); // Remove aria-label if target is not _blank or _self
      }
    });
  };

  // Handle content changes on input
  const handleContentChange = (event: ChangeEvent<HTMLDivElement>) => {
    onChange?.(event);
    updateAnchorAriaLabels();
  };

  // Use MutationObserver to detect changes in the DOM and update aria-labels accordingly
  useEffect(() => {
    if (!text || !editableDivRef.current) return; // Exit early if text is null or empty

    updateAnchorAriaLabels(); // Initial check

    const observer = new MutationObserver(updateAnchorAriaLabels);
    observer.observe(editableDivRef.current, { childList: true, subtree: true });

    return () => observer.disconnect(); // Cleanup observer on unmount
  }, [text]);

  return (
    <StyledRichText
      data-component-name="m-ui-library-RichText"
      data-testid="ui-library-RichText"
      id={componentId}
      className={clsx(styleclass ? styleclass : '', customClass)}
    >
      {text && (
        <div
          ref={editableDivRef}
          contentEditable={contentEditable}
          dangerouslySetInnerHTML={{ __html: text }}
          onInput={handleContentChange}
          data-testid={componentId}
          {...customProps}
        ></div>
      )}
    </StyledRichText>
  );
};
