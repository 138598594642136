import type { KeywordBlacklistEntry } from './createKeywordBlacklistChecker.types';
import { cleanKeyword, normalizeBlacklistedKeywords } from './utils';

/**
 * Creates a keyword blacklist checker function.
 *
 * This function takes a `KeywordBlacklistEntry` object (or `undefined`), normalizes the blacklisted keywords by trimming
 * spaces, removing special characters, converting to lowercase, and deduplicating entries. It returns a function that can
 * be used to check if a given keyword is blacklisted. The checker function cleans and converts the input keyword to
 * lowercase before comparing it against the normalized blacklist.
 *
 * @param {KeywordBlacklistEntry | undefined} blacklist - The object containing an optional list of blacklisted keyword entries.
 *
 * @returns {(keyword: string): boolean} A function that checks if a given keyword is in the blacklist.
 *                                          Returns `true` if the keyword is blacklisted, `false` otherwise.
 *
 * @example
 * const blacklist = { keywords: [{ value: '  forbidden ' }, { value: 'prohibited' }] };
 * const isKeywordBlacklisted = createKeywordBlacklistChecker(blacklist);
 *
 * console.log(isKeywordBlacklisted('forbidden')); // true
 * console.log(isKeywordBlacklisted('allowed'));   // false
 */
export const createKeywordBlacklistChecker = (
  blacklist: KeywordBlacklistEntry | undefined
): ((keyword: string) => boolean) => {
  const blacklistedKeywords = normalizeBlacklistedKeywords(blacklist);
  return (keyword: string) => blacklistedKeywords.has(cleanKeyword(keyword));
};
