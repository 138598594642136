// Styles for __name__ go here.
import styled from 'styled-components';
import { toRem, baseVariables, contextualVariable } from '@marriott/mi-ui-library';

export const StyledTab = styled.div`
  .m-standard-tab-list {
    overflow-x: hidden;
    border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral40']};
    .m-standard-tab-list-item {
      padding-bottom: ${toRem(0)};
      margin-top: ${toRem(1)};
      text-align: center;

      button {
        width: 100%;
        height: 100%;
      }

      &:not(:first-child) {
        button {
          padding-right: ${toRem(19)};
          padding-left: ${toRem(19)};

          @media ${baseVariables.mediaQuery.sm} {
            padding-right: ${toRem(24)};
          }
        }
      }
    }
  }

  .tabIconText {
    .iconContainer {
      font-size: ${toRem(32)};
      padding-bottom: ${toRem(8)};
    }
    span {
      text-align: center;
    }
    p,
    span::before {
      color: ${contextualVariable.brandedAltColor};
      .alternate & {
        color: ${baseVariables.color['neutral40']};
      }
    }
    display: grid;
    &:hover {
      p,
      span:before {
        color: ${contextualVariable.tabStdItemTitleFocusColor};
        .alternate & {
          color: ${contextualVariable.tabStdItemTitleFocusColor};
        }
      }
    }
  }

  .active {
    .tabIconText {
      p,
      span::before {
        color: ${contextualVariable.tabStdItemTitleFocusColor};
        .alternate & {
          color: ${contextualVariable.tabStdItemTitleFocusColor};
        }
      }
    }
  }

  .tabpage-container {
    margin-top: ${toRem(-4)};
  }
  .tabpage-edge {
    margin-top: ${toRem(44)};
  }
  .tabpage-border {
    border: ${toRem(1)} solid ${baseVariables.color['neutral30']};
  }

  @media ${baseVariables.mediaQuery.md} {
    padding: ${toRem(24)} ${toRem(0)} ${toRem(27)} ${toRem(0)};
  }
  @media ${baseVariables.mediaQuery.lg} {
    padding: ${toRem(32)} ${toRem(0)} ${toRem(27)} ${toRem(0)};
  }

  .glide {
    &__track {
      overflow: hidden;
      padding-top: ${toRem(24)};
      .glide__slides_authoring {
        @media ${baseVariables.mediaQuery.md} {
          flex-wrap: nowrap;
        }
        width: 100% !important;
        flex-wrap: wrap;
      }
      .glide__slides {
        .aem-container {
          padding: 0;
        }
      }
      .tabs-only {
        width: 100% !important;
      }
    }
    &__track__pt3 {
      overflow: hidden;
      padding-top: ${toRem(8)};
      .glide__slides_authoring {
        width: 100% !important;
        flex-wrap: wrap;
      }
      .glide__slides {
        .aem-container {
          padding: 0;
        }
      }
    }
    .center-align {
      display: flex;
      justify-content: center;
      align-items: center;
      .carouselControlType1.tab-controls {
        .left-arrow {
          height: ${toRem(80)};
          width: ${toRem(80)};
          border-radius: 0px;
          box-shadow: none;
          margin-top: ${toRem(12)};
          background: linear-gradient(
            90deg,
            #fff 0%,
            #fff 5%,
            rgba(255, 255, 255, 0.64) 100%,
            rgba(255, 255, 255, 0) 100%,
            rgba(255, 255, 255, 0) 100%
          );
          color: ${baseVariables.color['base10']};
          top: 0;
          left: ${toRem(0)};
          .icon-arrow-left {
            position: absolute;
            left: ${toRem(8)};
            color: ${contextualVariable.buttonPrimaryBgColor};
            opacity: 1;
          }
        }
        .left-arrow.inv-arrow {
          background: linear-gradient(
            90deg,
            #1c1c1c 0%,
            #1c1c1c 5%,
            rgba(28, 28, 28, 0.64) 100%,
            rgba(28, 28, 28, 0) 100%,
            rgba(28, 28, 28, 0) 100%
          );
          color: ${baseVariables.color['base20']};
        }
        .left-arrow.alt-arrow {
          background: linear-gradient(
            90deg,
            #f4f4f4 0%,
            #f4f4f4 5%,
            rgba(244, 244, 244, 0.64) 100%,
            rgba(244, 244, 244, 0) 100%,
            rgba(244, 244, 244, 0) 100%
          );
        }
        .right-arrow {
          height: ${toRem(80)};
          width: ${toRem(80)};
          box-shadow: none;
          border-radius: 0px;
          margin-top: ${toRem(12)};
          background: linear-gradient(
            270deg,
            #fff 0%,
            #fff 5%,
            rgba(255, 255, 255, 0.64) 100%,
            rgba(255, 255, 255, 0) 100%,
            rgba(255, 255, 255, 0) 100%
          );
          color: ${baseVariables.color['base10']};
          top: 0;
          right: ${toRem(0)};
          .icon-arrow-right {
            position: absolute;
            right: ${toRem(8)};
            color: ${contextualVariable.buttonPrimaryBgColor};
            opacity: 1;
          }
        }
        .right-arrow.inv-arrow {
          background: linear-gradient(
            270deg,
            #1c1c1c 0%,
            #1c1c1c 5%,
            rgba(28, 28, 28, 0.64) 100%,
            rgba(28, 28, 28, 0) 100%,
            rgba(28, 28, 28, 0) 100%
          );
          color: ${baseVariables.color['base20']};
        }
        .right-arrow.alt-arrow {
          background: linear-gradient(
            270deg,
            #f4f4f4 0%,
            #f4f4f4 5%,
            rgba(244, 244, 244, 0.64) 100%,
            rgba(244, 244, 244, 0) 100%,
            rgba(244, 244, 244, 0) 100%
          );
        }
      }
    }
  }
`;
