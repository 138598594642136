import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledBrandFilter = styled.div`
  .brand-filter__category_list {
    list-style: none;
  }
  .tabbed-search-form-brand-category-list {
    gap: ${toRem(16)};
    display: grid !important;
    grid-template-columns: auto auto;
    @media only ${baseVariables.mediaQuery.md} {
      grid-template-columns: auto auto auto auto;
    }
    @media only ${baseVariables.mediaQuery.lg} {
      grid-template-columns: auto auto auto;
    }
  }
  .hotel-directory-brand-list {
    grid-template-columns: auto auto auto;
    @media only ${baseVariables.mediaQuery.md} {
      grid-template-columns: auto auto auto auto;
    }
    @media only ${baseVariables.mediaQuery.lg} {
      grid-template-columns: auto auto auto auto;
    }
  }
  .adv-search-brand-filter-label {
    letter-spacing: ${toRem(1.2)};
  }
  .brand-logo-container {
    box-sizing: border-box;
    height: ${toRem(62)};
    width: ${toRem(92)};
    min-height: ${toRem(62)};
    min-width: ${toRem(92)};
    border: ${toRem(1)} solid ${baseVariables.color.neutral30};
    border-radius: ${toRem(8)};
    margin: 0 ${toRem(10)} ${toRem(16)} 0;
    background-color: ${baseVariables.color.base20};
    cursor: pointer;

    &:hover {
      box-shadow: 0 ${toRem(2)} ${toRem(4)} 0 rgba(0, 0, 0, 0.25);
    }
    .brand-logo {
      &:hover {
        background-color: transparent;
      }
    }
    .brand-logo-disabled {
      color: ${baseVariables.color.neutral30};
    }
    &.active {
      border: ${toRem(2)} solid ${baseVariables.color.base10};
      box-shadow: none;
    }
    &.disabled {
      background-color: ${baseVariables.color.neutral10};
      cursor: not-allowed;
      pointer-events: none;
      &:hover {
        box-shadow: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }

  @media only ${baseVariables.mediaQuery.tablet} {
    .brand-logo-container {
      height: ${toRem(72)};
      width: ${toRem(158)};
      min-height: ${toRem(72)};
      min-width: ${toRem(158)};
    }
  }

  .adv-search-select-btn {
    padding: 0;
  }
  .brand-filter__category_label_btn {
    background: transparent;
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: underline;
    font: ${baseVariables.font.fontWeightMedium};
    &.disabled {
      cursor: not-allowed;
      color: ${baseVariables.color.neutral30};
      pointer-events: none;
    }
  }
  .tab-focus {
    &:focus-visible {
      outline: -webkit-focus-ring-color auto ${toRem(1)};
    }
  }

  @media only ${baseVariables.mediaQuery.allTablets} {
    .brand-logo-container {
      cursor: pointer;
      &:hover {
        box-shadow: none;
      }
    }
  }

  .brand-filter__category_label {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    align-items: center;
    span.label-seperator {
      padding-right: ${toRem(6)};
      border-right: ${baseVariables.border.borderWidth03} solid ${baseVariables.color.neutral40};
    }
  }
`;

export const StyledBrandsList = styled.ul`
  ${rtl`
  
  .adv-search-brand-pill {
    width: auto;
    height: ${toRem(80)};
    min-width: ${toRem(106)};
    min-height: ${toRem(80)};
    border-color: ${baseVariables.color.neutral30};
    margin-right: 0;
    margin-bottom: 0;

    @media only ${baseVariables.mediaQuery.md} {
      width: auto;
      height: ${toRem(74)};
      min-width: ${toRem(158)};
      min-height: ${toRem(74)};

    }
    @media only ${baseVariables.mediaQuery.lg} {
      width: auto;
      height: ${toRem(62)};
      min-width: ${toRem(94)};
      min-height: ${toRem(62)};
    }
  }
  .adv-search-brand-list{
    width: 100%;
  }

  .brand-logo-XE::before {
    font-size: ${toRem(37)};
    margin-top: ${toRem(15)};
  }

  .brand-logo-EB::before {
    font-size: ${toRem(14)};
  }
  .brand-logo-RZ::before {
    font-size: ${toRem(36)};
  }
  .brand-logo-LC::before {
    font-size: ${toRem(42)};
  }
  .brand-logo-XR::before {
    font-size: ${toRem(48)};
  }
  .brand-logo-WH::before {
    font-size: ${toRem(11)};

    @media only ${baseVariables.mediaQuery.tablet} {
      font-size: ${toRem(10)};
    }
  }
  .brand-logo-JW::before {
    font-size: ${toRem(30)};
  }
  .brand-logo-BG::before {
    font-size: ${toRem(20)};
  }
  .brand-logo-MC::before {
    font-size: ${toRem(48)};
  }
  .brand-logo-SI::before {
    font-size: ${toRem(36)};
  }
  .brand-logo-MV::before {
    font-size: ${toRem(44)};
  }
  .brand-logo-DE::before {
    font-size: ${toRem(48)};
  }
  .brand-logo-WI::before {
    font-size: ${toRem(18)};
  }
  .brand-logo-MD::before {
    font-size: ${toRem(12)};
  }
  .brand-logo-BR::before {
    font-size: ${toRem(40)};
    margin-top: ${toRem(10)};
  }
  .brand-logo-AK::before {
    font-size: ${toRem(30)};
  }
  .brand-logo-TX::before {
    font-size: ${toRem(16)};
  }
  .brand-logo-DS::before {
    font-size: ${toRem(9)};
  }
  .brand-logo-GE::before {
    font-size: ${toRem(36)};
  }
  .brand-logo-CY::before {
    font-size: ${toRem(12)};
  }
  .brand-logo-FP::before {
    font-size: ${toRem(46)};
    margin-top: ${toRem(10)};
  }
  .brand-logo-SH::before {
    font-size: ${toRem(30)};
  }
  .brand-logo-FI::before {
    font-size: ${toRem(20)};
  }
  .brand-logo-PR::before {
    font-size: ${toRem(32)};
  }
  .brand-logo-AR::before {
    font-size: ${toRem(48)};
  }
  .brand-logo-AL::before {
    font-size: ${toRem(34)};
  }
  .brand-logo-OX::before {
    font-size: ${toRem(26)};
  }
  .brand-logo-RI::before {
    font-size: ${toRem(14)};
  }
  .brand-logo-TS::before {
    font-size: ${toRem(26)};
  }
  .brand-logo-ER::before {
    font-size: ${toRem(34)};
  }
  .brand-logo-EL::before {
    font-size: ${toRem(48)};
  }
  .brand-logo-XE::before {
    font-size: ${toRem(24)};
    margin-top: ${toRem(6)};
  }
  .brand-logo-RS::before {
    font-size: ${toRem(18)};
    margin-top: ${toRem(5)};
  }

  .brand-logo-MG::before {
    font-size: ${toRem(24)};
    margin-top: ${toRem(6)};
  }
  .brand-logo-XF::before {
    font-size: ${toRem(24)};
    margin-top: ${toRem(10)};
  }
  .brand-logo-BA::before {
    font-size: ${toRem(40)};
    margin-top: ${toRem(12)};
  }
  .brand-logo-SN::before {
    font-size: ${toRem(50)};
    margin-top: ${toRem(15)};
  }

  @media only ${baseVariables.mediaQuery.smallMobile} {
    width: 100%;
  }
`}
`;
