import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';
import { logoResize } from '../../utils/logoResize';
import { FONT_ICON_1_1_SIZE } from '../../utils/enums/enums';

export const StyledHorzMiniCardContainer = styled.div<{ openInaNewTab: boolean; fontIcon: string }>`
  position: relative;
  border: ${baseVariables.border.borderWidth02} ${baseVariables.border.borderStyleDefault}
    ${baseVariables.color.neutral20};
  height: ${toRem(100)};
  border-radius: ${toRem(12)};
  overflow: hidden;
  box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  padding: 0;

  &.miniCard {
    &--height {
      height: auto;
      align-items: center;
    }
  }

  &.icon-grey-background {
    .miniCardImage {
      background-color: ${baseVariables.color['neutral30']};
    }
  }

  [class*='brand-logo-'] {
    font-weight: 500;
    outline-offset: 0.25rem;
  }
  [class*='brand-logo-']:before {
    ${props => logoResize(props.fontIcon, FONT_ICON_1_1_SIZE)}
  }

  .miniCardImage {
    grid-area: image;
    width: ${toRem(100)};
    height: ${toRem(100)};
    ${props =>
      props.fontIcon &&
      `
    background-color: ${baseVariables.color['bvyAccent10']};
    display:flex;
    span::before {
      font-size: ${toRem(33)};
      line-height: ${toRem(33)};
      color: ${baseVariables.color['base10']};
      font-weight: ${baseVariables.font['fontWeightMedium']};
    }
  `}
  }

  .miniCardContent {
    grid-area: content;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .miniCardBody {
      width: 80%;
      padding-right: ${toRem(20)};

      [class*='t-subtitle-'] {
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: ${toRem(5)};
      }

      .clampLines {
        display: -webkit-box; /* stylelint-disable-line */
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; /* stylelint-disable-line */
        white-space: normal;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        display: inline;
      }

      [class*='t-font-'] {
        max-height: 1.5rem;
        overflow: hidden;
        * {
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
        }
      }
      &--wrapText {
        width: 80%;
        @media ${baseVariables.mediaQuery.lg} {
          padding-right: ${toRem(20)};
        }
      }
    }

    .miniCardChevron {
      position: absolute;
      right: ${toRem(16)};
    }

    &__link {
      text-decoration: underline;
    }
  }

  .minicardlink {
    display: grid;
    grid-template-areas: 'image content';
    gap: ${toRem(16)};
    grid-template-columns: ${toRem(100)} calc(100% - ${toRem(116)});
    align-items: center;
    height: 100%;
    &.icon-arrow-right-external {
      &::before {
        font-size: ${toRem(16)};
        right: ${toRem(16)};
      }
      &:lang(ar-AE)::before {
        left: ${toRem(16)};
        right: auto;
        transform: rotate(180deg);
      }
    }
  }
`;
