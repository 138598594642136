// Styles for RichText go here.
import styled from 'styled-components';
import { baseVariables } from '../../styles';

export const StyledRichText = styled.div`
  .custom-link {
    display: block;
    @media ${baseVariables.mediaQuery.md} {
      display: inline;
    }
  }
  .line-separator {
    display: none;
    @media ${baseVariables.mediaQuery.md} {
      display: inline;
    }
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }

  a:has(span.m-link-action),
  a:has(span.m-link-tertiary-button-external) {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  span.m-link-action a,
  span.m-link-tertiary-button-external a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  .m-link-action {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    border-bottom-color: inherit;
  }
  .m-link-tertiary-button-external {
    width: unset !important;
    overflow: visible !important;
    font-size: inherit;
  }
`;
