import { TrackingPropsInterface } from './enums/enums';
import { trackingDetailArr, config } from '../constants/ApplicationConstants';
import CookieUtils from '../utils/CookieUtils';

// import { getTrackingProps } from '@marriott/mi-book-components';
declare global {
  interface Window {
    impressionArr: string[];
    impressionTrack: (value: string) => Record<string, string>;
    atCArray: Array<string>;
    atMArray: Array<string>;
    // for reference check libs/shared/mi-headless-utils/src/hooks/use-datalayer.ts
    adobeDataLayer: {
      [key: number]: {
        event: string;
        data: Record<string, unknown>;
      };
      version: number;
      push: (event: { event: string; data: Record<string, unknown> }) => void;
      getState: () => Record<string, unknown>;
      addEventListener: (type: string, listener: EventListenerOrEventListenerObject) => void;
      removeEventListener: (type: string, listener: EventListenerOrEventListenerObject) => void;
    };
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getClickTrackValue = (linkType: string, trackingProperties?: TrackingPropsInterface, ref?: any) => {
  let trackPosAndDesc = '';
  const {
    trackingContentPosition,
    isImpressionTrack,
    trackingDescription,
    trackingOfferType,
    trackingTag,
    isCoBrand,
    isClickTrack,
    atCCeVar48,
    compName,
  } = ref?.current.dataset || {};
  const RefCurrent = ref?.current;
  if (isCoBrand === 'true') {
    if (atCCeVar48) {
      let atCTAclickTrackVal;
      if (
        isClickTrack === 'true' &&
        isImpressionTrack === 'true' &&
        !RefCurrent?.classList.contains('no-merch-track')
      ) {
        // hero cta link tag needs to include custom attribute custom_click_track_value where the value is the atCTAclickTrackVal variable
        if (trackingProperties?.isDCAEnabled) {
          atCTAclickTrackVal = `${trackingContentPosition}|${trackingDescription}|${linkType}|evar48=${atCCeVar48},event167,event271`;
          RefCurrent?.setAttribute('data-custom_click_track_value', atCTAclickTrackVal);
        } else {
          atCTAclickTrackVal = compName === 'herobanner' ? `${config.HIO}|` : `${trackingContentPosition}|`;
          atCTAclickTrackVal += `${
            atCCeVar48.split('-')[4] + atCCeVar48.split('-')[5]
          }|${linkType}|evar48=${atCCeVar48},event167,event271`;
          RefCurrent?.setAttribute('data-custom_click_track_value', atCTAclickTrackVal);
        }
      }
      if (
        isClickTrack === 'true' &&
        isImpressionTrack === 'false' &&
        !RefCurrent?.classList.contains('no-merch-track')
      ) {
        atCTAclickTrackVal = `${trackingContentPosition}|${trackingDescription}|${linkType}|evar48=${atCCeVar48},event167,event271`;
        RefCurrent?.setAttribute('data-custom_click_track_value', atCTAclickTrackVal);
      }
      // update href value

      const updatedHref = trackingProperties?.isDCAEnabled
        ? getDCAHref(trackingProperties, RefCurrent.getAttribute('href') ?? '')
        : getHref(RefCurrent.dataset, RefCurrent.getAttribute('href') ?? '');
      RefCurrent?.setAttribute('href', updatedHref);
      if (!RefCurrent?.classList.contains('no-impression') && typeof window.impressionTrack !== 'undefined') {
        window.impressionTrack('event168|event270|evar48=' + atCCeVar48);
      }
    }
  } else {
    if ((isClickTrack === 'true' && isImpressionTrack === 'true') || trackingProperties?.clickTrackValSeparator) {
      if (trackingProperties?.isDCAEnabled) {
        const trackPosAndDesc = `${trackingContentPosition}|${trackingDescription}|${linkType}|event167,list3=${trackingContentPosition};type=${trackingOfferType};msg=${trackingDescription};tag=${trackingTag}`;
        RefCurrent?.setAttribute('data-custom_click_track_value', `${trackPosAndDesc}`);
      } else {
        let trackPosAndDesc = compName === 'herobanner' ? `${config.HIO}|` : `${trackingContentPosition}|`;
        if (trackingDescription) {
          trackPosAndDesc += `${trackingDescription}|`;
        }
        RefCurrent?.setAttribute(
          'data-custom_click_track_value',
          `${trackPosAndDesc}${linkType}|event167,list3=${
            compName === 'herobanner' ? `hpHero` : `${trackingContentPosition}`
          }${trackingProperties?.additionalTrackingVariables ? getTrackingProps(trackingProperties) : ''}`
        );
      }
    }
    if (isClickTrack === 'true' && isImpressionTrack === 'false' && !trackingProperties?.clickTrackValSeparator) {
      if (trackingProperties?.isDCAEnabled) {
        const trackPosAndDesc = `${trackingContentPosition}|${trackingDescription}|${linkType}|type=${trackingOfferType};msg=${trackingDescription};tag=${trackingTag}`;
        RefCurrent?.setAttribute('data-custom_click_track_value', `${trackPosAndDesc}`);
      } else {
        if (trackingContentPosition) {
          trackPosAndDesc += `${trackingContentPosition}|`;
        }
        if (trackingDescription) {
          trackPosAndDesc += `${trackingDescription}|`;
        }
        RefCurrent?.setAttribute(
          'data-custom_click_track_value',
          `${trackPosAndDesc}${linkType}${
            trackingProperties?.additionalTrackingVariables ? getTrackingProps(trackingProperties) : ''
          }`
        );
      }
    }
    if (
      !RefCurrent?.classList.contains('no-impression') &&
      isImpressionTrack === 'true' &&
      (trackingContentPosition || trackingDescription || trackingOfferType || trackingTag)
    ) {
      if (typeof window.atMArray !== 'object') {
        window.atMArray = [];
      }
      const atmMValue = `${trackingContentPosition},type=${trackingOfferType},msg=${trackingDescription},tag=${trackingTag}`;
      if (!window.atMArray.includes(atmMValue)) {
        window.atMArray.push(atmMValue);
      }
    }
  }
  // update atCArray
  setMerchImpTrack(trackingProperties, RefCurrent);
};

export const getTrackingProps = (trackingProperties?: TrackingPropsInterface) => {
  let trackingVar = ``;
  const clickTrackValSeparator = trackingProperties?.clickTrackValSeparator || ',';
  if (trackingProperties?.trackingOfferType) {
    trackingVar += `${clickTrackValSeparator}type=${trackingProperties?.trackingOfferType}${clickTrackValSeparator}msg=${trackingProperties?.trackingDescription}`;
  }
  if (trackingProperties?.trackingTag) {
    trackingVar += `${clickTrackValSeparator}tag=${trackingProperties?.trackingTag}`;
  }
  return trackingVar;
};

export const trackImpression = (trackingProperties: TrackingPropsInterface, text: string, preTrackVal?: string) => {
  if (trackingProperties?.isCoBrand) {
    return;
  }
  const tracking = getTrackingProperties(trackingProperties || {}, ',');
  const preTrackValue = preTrackVal ? preTrackVal + '|' : '';
  if (trackingProperties?.impressionTrack && !window.impressionArr?.includes(`${tracking.trackingString}${text}`)) {
    const ImpressionEventType = trackingProperties?.['impressionEventType'] || 'event168';
    if (window?.impressionTrack) {
      window.impressionTrack(
        `${preTrackValue}${ImpressionEventType}|${tracking?.payloadType}=${tracking.trackingString}`
      );
      if (typeof window.impressionArr === 'object') {
        window.impressionArr?.push(`${tracking.trackingString}${text}`);
      } else {
        window.impressionArr = [`${tracking.trackingString}${text}`];
      }
    }
  }
};

const getTrackingProperties = (trackingProperties: TrackingPropsInterface, seperator: string) => {
  const trackingArr: Array<string> = [];
  trackingDetailArr.forEach(item => {
    if (item.text) {
      trackingArr.push(`${item.pre}${trackingProperties?.[item.text]}`);
    }
  });
  return {
    trackingString: trackingArr.join(seperator),
    payloadType: trackingProperties?.payloadType || 'list3',
  };
};

const getHref = (data: DOMStringMap, atCCctaUrl: string): string => {
  /* creates CTA dynamic URL value in variable atCCctaUrl, use this as the CTA button URL */
  const atCCaltID: string = CookieUtils.getCookie('dtm_user_id') || CookieUtils.getCookie('customerId') || '';
  const atCCmcvID = mtGCV(config.gcv) || '';

  let URL;

  if (data['atCCeVar48']?.split('-')[4] === config.code1 || data['atCCeVar48']?.split('-')[4] === config.code2) {
    URL =
      config.ctaURL +
      encodeURIComponent(
        atCCctaUrl + '&alt_cust_id=' + atCCaltID + '&cookie_id=' + atCCmcvID + '&mcd=' + data['atCCeVar48']
      );
  } else if (atCCctaUrl?.includes('?') && !atCCctaUrl?.includes('destinationURL')) {
    URL = atCCctaUrl + '&alt_cust_id=' + atCCaltID + '&cookie_id=' + atCCmcvID + '&mcd=' + data['atCCeVar48'];
  } else {
    URL = atCCctaUrl + '?alt_cust_id=' + atCCaltID + '&cookie_id=' + atCCmcvID + '&mcd=' + data['atCCeVar48'];
  }
  return URL;
};

const getDCAEverGreenHref = (atCCeVar48: string, atCCctaUrl: string): string => {
  /* creates CTA dynamic URL value in variable atCCctaUrl, use this as the CTA button URL */
  const atCCaltID: string =
    CookieUtils.getCookie('dtm_user_id') ||
    CookieUtils.getCookie('customerId') ||
    (window?.['adobeDataLayer']?.[0]?.data?.['mr_id_alternate'] as string) ||
    (window?.dataLayer?.['mr_id_alternate'] as string) ||
    '';
  const atCCmcvID = mtGCV(encodeURIComponent(config.gcv)) || '';
  const atCCrpcCode = atCCeVar48.split('-')[4];
  let URL;

  if (atCCrpcCode === config.code1 || atCCrpcCode === config.code2) {
    URL =
      atCCctaUrl +
      config.ctaUrlChasePrefix +
      config.ctaUrlChase +
      '&alt_cust_id=' +
      atCCaltID +
      '&cookie_id=' +
      atCCmcvID +
      '&mcd=' +
      atCCeVar48 +
      '&rpcCode=' +
      atCCrpcCode;
  } else if (/(USBU|USPC|USMT)/.test(atCCrpcCode)) {
    if (!atCCctaUrl.includes('destinationURL')) {
      atCCctaUrl = config.ctaUrlAmexEvergreen + atCCctaUrl;
      URL =
        atCCctaUrl +
        encodeURIComponent(
          '?alt_cust_id=' + atCCaltID + '&cookie_id=' + atCCmcvID + '&mcd=' + atCCeVar48 + '&rpcCode=' + atCCrpcCode
        );
    } else {
      URL =
        atCCctaUrl +
        encodeURIComponent(
          '?alt_cust_id=' + atCCaltID + '&cookie_id=' + atCCmcvID + '&mcd=' + atCCeVar48 + '&rpcCode=' + atCCrpcCode
        );
    }
  }
  return URL as string;
};

const getDCAHref = (trackingProperties: TrackingPropsInterface, atCCctaUrl: string): string => {
  if (typeof window !== 'undefined' && window?.location?.search?.includes('destinationUrlUpdate=true')) {
    // if this query parameter exist with value true, then no need to construct url.
    return atCCctaUrl;
  }
  if (!trackingProperties?.type) {
    return getDCAEverGreenHref(trackingProperties.trackingDescription as string, atCCctaUrl as string);
  }
  /* creates CTA dynamic URL value in variable atCCctaUrl, use this as the CTA button URL */
  const atCCaltID: string =
    CookieUtils.getCookie('dtm_user_id') ||
    CookieUtils.getCookie('customerId') ||
    (window?.['adobeDataLayer']?.[0]?.data?.['mr_id_alternate'] as string) ||
    (window?.dataLayer?.['mr_id_alternate'] as string) ||
    '';
  const atCCmcvID: string = mtGCV(encodeURIComponent(config.gcv)) || '';
  const atCCeVar48: string = trackingProperties.atCCeVar48 || '';
  const cell: string = trackingProperties.cell || '';
  const spid: string = trackingProperties.spid || '';
  const rpcCode: string = trackingProperties.rpcCode || '';
  const offerCode: string = trackingProperties?.offerCode || '';
  const isAuth: boolean = trackingProperties?.isAuth || false;
  let atCCctaUrlVersion: URL;
  try {
    atCCctaUrlVersion = new URL(atCCctaUrl);
  } catch {
    atCCctaUrlVersion = new URL(window.location.protocol + window.location.hostname + atCCctaUrl);
  }

  // Befere appending query params, first remove query params which are being added by uxl
  let listOfQueries = [];
  const queriesAddedByUXl = ['cell', 'spid', 'returnurl', 'alt_cust_id', 'cookie_id', 'mcd', 'rpccode']; // saved it in lower case, for safer comparision
  try {
    for (const key of atCCctaUrlVersion.searchParams.keys()) {
      listOfQueries.push(key);
    }
  } catch {
    listOfQueries = [];
  }
  listOfQueries.forEach(queryParam => {
    if (queriesAddedByUXl?.includes(queryParam?.toLowerCase())) atCCctaUrlVersion.searchParams.delete(queryParam);
  });

  if (cell) {
    atCCctaUrlVersion.searchParams.append('CELL', cell);
  }
  if (spid) {
    atCCctaUrlVersion.searchParams.append('SPID', spid);
  }
  if ((rpcCode === config.code1 || rpcCode === config.code2) && offerCode && offerCode.includes('ACQ')) {
    // if US Chase acquisition authenticated / unauthenticated include return URL
    atCCctaUrlVersion.searchParams.append('returnURL', window.location.pathname);
    atCCctaUrlVersion.searchParams.append('alt_cust_id', atCCaltID);
    atCCctaUrlVersion.searchParams.append('cookie_id', atCCmcvID);
    atCCctaUrlVersion.searchParams.append('mcd', atCCeVar48);
    atCCctaUrlVersion.searchParams.append('rpcCode', rpcCode);
    return decodeURIComponent(atCCctaUrlVersion.href);
  } else if (/(USBU|USPC|USMT)/.test(rpcCode) && /(ACQ)/.test(offerCode) && isAuth) {
    const encodedQueryString = encodeURIComponent(
      `?alt_cust_id=${atCCaltID}&cookie_id=${atCCmcvID}&mcd=${atCCeVar48}&rpcCode=${rpcCode}`
    );

    return config.ctaUrlAmex + atCCctaUrlVersion.href + encodedQueryString;
  } else {
    atCCctaUrlVersion.searchParams.append('alt_cust_id', atCCaltID);
    atCCctaUrlVersion.searchParams.append('cookie_id', atCCmcvID);
    atCCctaUrlVersion.searchParams.append('mcd', atCCeVar48);
    atCCctaUrlVersion.searchParams.append('rpcCode', rpcCode);
    return decodeURIComponent(atCCctaUrlVersion.href);
  }
};

const mtGCV = (cname: string): string => {
  const x: Array<string> = mtGC(cname).split('|');
  let i;
  for (i = 0; i < x.length; i++) {
    if (x[i] === config.cookie) {
      return x[i + 1];
    }
  }
  return '';
};

const mtGC = (cname: string): string => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      const cnameValue = c.substring(name.length, c.length);
      try {
        return decodeURIComponent(cnameValue);
      } catch {
        return cnameValue;
      }
    }
  }
  return '';
};

const setMerchImpTrack = (data: TrackingPropsInterface | undefined, ref?: HTMLElement): void => {
  // sets merchandising impression tracking
  if (
    data?.['trackingContentPosition'] &&
    data?.['trackingDescription'] &&
    (data?.['compName'] || !ref?.classList.contains('no-impression')) &&
    data?.['impressionCount']
  ) {
    const cname = config.cname;
    const ctemp = CookieUtils.getCookie(cname) || '';
    const cvalue = `${data?.['trackingContentPosition']}-${
      data?.isDCAEnabled ? data?.['trackingTag'] : data?.['trackingDescription']
    }`;
    if (ctemp.includes(cvalue) !== true) {
      if (typeof window.atCArray !== 'object') {
        window.atCArray = [];
      }
      if (!window.atCArray.includes(cvalue)) {
        window.atCArray.push(cvalue);
      }
    }
  }
};
