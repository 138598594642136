import styled from 'styled-components';

import { toRem } from '@marriott/mi-ui-library';

type StickyProps = {
  $isSticky: boolean;
  $offset: number;
  $top: number;
};

export const StyledStickyComponentWrapper = styled.div<StickyProps>`
  width: 100vw;
  left: 0;
  position: ${({ $isSticky }) => ($isSticky ? 'fixed' : 'absolute')};
  top: ${({ $isSticky, $top, $offset }) => ($isSticky ? toRem($top) : toRem($offset))};
  ${({ $isSticky, $top }) => ($isSticky && $top !== 0 ? 'transition: top 0.4s ease 0s;' : '')}
  ${({ $isSticky }) => ($isSticky ? 'z-index: 50' : '')};
`;
