export const LEISURE_REGION_LIST = 'leisureRegionList';
export const STATE_COUNTRY_LIST = 'stateORCountryList';
export const SELECTED_REGION = 'selectedRegion';
export const SHOW_PAGE_LEVEL_ALERT = 'showPageLevelAlert';
export const SHOW_REGION_CARD_ERROR = 'showRegionCardError';
export const TOTAL_NO_OF_HOTELS_WORLDWIDE = `totalNoOfHotelsWorldwide`;
export const DEFAULT_CENTER = { lat: 35.0077519, lng: -97.092877 };
export const svgIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73">
<!-- Outer black circle -->
<circle cx="36.5" cy="36.5" r="36.5" fill="black" opacity='0.1' />

<!-- Inner white circle -->
<circle cx="36.5" cy="36.5" r="25" fill="white" />
</svg>
`;

export const submitSearchURL = '/search/findHotels.mi';
export const destinationParamURL = '?destinationAddress.destination=';
export const countryParamURL = '&destinationAddress.country=';
export const stateProvinceParamURL = '&destinationAddress.stateProvince=';
export const restParamsURL = '&searchType=InCity&filterApplied=';
export const destinationRedirectURL = '/de/destinations/';
export const destinationRegionURL = '&destinationAddress.region=';

export const propertiesToProcess = [
  'activities',
  'brands',
  'transportationTypes',
  'hotelTypes',
  'events',
  'states',
  'cities',
  'amenities',
  'propertyType',
];

export const TRACKING_CONST = {
  HOTEL_DIRECTORY: 'Phoenix Hotel Directory',
  ALL_FILTERS: 'Filters',
  REGION_SECTION: 'View Region Dropdown',
  REGION_CARD: 'Region Card',
  AMENITIES_FILTERS: 'Amenities',
  BRANDS_FILTERS: 'Brands',
  WITH_IN_REGION: 'View Hotels within Region',
};

export const DEFAULT_ZOOM_LEVEL = 4;

export const MEXICO_REGION_CODE = 'MX';
export const MEXICO_CODE = 'mexico';

export const CANADA_REGION_CODE = 'CA';
export const CANADA_CODE = 'canada';

export const CHINA_REGION_CODE = 'CN';
export const TAIWAN_REGION_CODE = 'TW';
export const MACAU_REGION_CODE = 'MO';
export const HONG_KONG_REGION_CODE = 'HK';
export const CHINA_LABEL_FOR_MAP = 'Mainland China and Hong Kong, Macau, Taiwan';
export const ASIA_AEM_CODE = 'asia';

export const ASIA_CODE = 'asia';
export const USA_CODE = 'united-states';
export const USA_LABEL = 'United States'; //TODO : All occurencess of this label will be replaced by AEM label

export const CARIBBEAN_LABEL = 'Caribbean';

export const USA_AEM_CODE = 'USA';

export const AUSTRALIA_CODE = 'australia-pi';
export const MIDDLE_EAST_CODE = 'middle-east';
export const SOUTH_AMERICA_CODE = 'south-america';
export const CENTRAL_AMERICA_CODE = 'central-america';

export const AUSTRALIA_LABEL = 'Australia & Pacific Islands'; //TODO : All occurencess of this label will be replaced by AEM label

export const AUSTRALIA_AEM_CODE = 'Australia & Oceania';

export const CANADA_CUSTOM_LABEL = 'canada';
export const AUSTRALIA_CUSTOM_LABEL = 'australia & oceania';
export const MEXICO_CUSTOM_LABEL = 'mexico';
export const EUROPE_CUSTOM_LABEL = 'europe';
export const ASIA_CUSTOM_LABEL = 'asia';
export const USA_CUSTOM_LABEL = 'usa';
export const SOUTH_AMERICA_CUSTOM_LABEL = 'south america';
export const CARIBBEAN_CUSTOM_LABEL = 'caribbean';
export const AFRICA_CUSTOM_LABEL = 'africa';
export const CENTRAL_AMERICA_CUSTOM_LABEL = 'central america';
export const MIDDLE_EAST_CUSTOM_LABEL = 'middle east';

export const facetTypeMapping: Record<string, string> = {
  ACTIVITIES: 'activities-on-site',
  MEETINGS_EVENTS: 'meetings-and-events',
};

export const SESSION_KEYS =
  'sessionToken,rewardsId,memberLevel,name,accessToken,consumerID,propertyId,AriesReservation.propertyId,AriesReservation.errorMessages,AriesCommon.prop_name,AriesCommon.memState,AriesCommon.search_destination_city,AriesCommon.search_destination_country,AriesCommon.search_destination_state,AriesCommon.search_is_weekend_stay,AriesCommon.search_brands,AriesSearch.searchCriteria,AriesSearch.search_keyword,AriesSearch.propertyFilterCriteria,AriesSearch.search_criteria_changed,AriesSearch.propertyId,AriesSearch.errorMessages';
