export enum size {
  small = 's',
  medium = 'm',
  large = 'l',
  extraLarge = 'xl',
  extraSmall = 'xs',
}

export enum headingType {
  title = 'title',
  subtitle = 'subtitle',
  body = 'font',
}

export enum tags {
  span = 'span',
  div = 'div',
  paragraph = 'p',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
}

export enum BadgeVariation {
  'Inline' = 'inline',
  'Overlay' = 'overlay',
}

export enum ButtonTypeVariation {
  'Submit' = 'submit',
  'Reset' = 'reset',
  'Button' = 'button',
}

export enum CheckBoxVariation {
  'Simple' = 'simple',
  'Switch' = 'switch',
}

export enum Themes {
  'Standard' = 'standard',
  'Inverse' = 'inverse',
  'Alternate' = 'alternate',
}

export enum ScrimStyle {
  DARK_SCRIM = 'dark-scrim',
  LIGHT_SCRIM = 'light-scrim',
  NO_SCRIM = 'no-scrim',
}

export enum LinkTargetType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export enum TypographyColorVariations {
  'Inverse' = 'inverse',
  'Alternate' = 'alt',
  'InverseAlt' = 'inverse-alt',
}

export interface TrackingPropsInterface {
  trackingContentPosition?: string;
  trackingOfferType?: string;
  atCCeVar48?: string;
  trackingDescription?: string;
  trackingTag?: string;
  isCoBrand?: boolean;
  clickTrack?: boolean;
  impressionTrack?: boolean;
  impressionCount?: boolean;
  impressionEventType?: string;
  merchandisingCategory?: string;
  additionalTrackingVariables?: string;
  location?: string;
  payloadType?: string;
  compName?: string;
  enableScrollingBehavior?: boolean;
  isAuth?: boolean;
  clickTrackValSeparator?: string;
  cell?: string;
  spid?: string;
  rpcCode?: string;
  isDCAEnabled?: boolean;
  offerCode?: string;
  type?: string;
}

export interface TrackingPropsCarousel {
  cardLocation?: string;
  leftArrowDesc?: string;
  rightArrowDesc?: string;
  description?: string;
}

export interface TrackingDetailsProps {
  text: 'trackingContentPosition' | 'trackingDescription' | 'trackingOfferType' | 'trackingTag';
  pre: string;
}

export interface renditions {
  altText: string;
  assetPath: string;
  dynamic: boolean;
  renditions: Array<{
    renditionPath: string;
    mediaValue: string;
    dynamic: boolean;
    damPath: string;
    mediaQuery: string;
    width: number;
    height: number;
  }>;
}

export enum ButtonStyle {
  PRIMARY_BUTTON = 'm-button-primary',
  PRIMARY_BUTTON_EXTERNAL = 'm-button-primary m-button-external',
  SECONDARY_BUTTON = 'm-button-secondary',
  SECONDARY_BUTTON_EXTERNAL = 'm-button-secondary m-button-external',
  TERTIARY_BUTTON = 'm-link-tertiary-button',
  TERTIARY_BUTTON_EXTERNAL = 'm-link-tertiary-button-external',
}

//example use case: window.mathMedia();
export enum MediaQueries {
  DESKTOP = '(min-width: 992px)',
}

export interface FontIconSize {
  mobileSize: number;
  deskTopSize: number;
}

export const FONT_ICON_1_1_SIZE: Record<string, FontIconSize> = {
  RZ: { mobileSize: 38.4, deskTopSize: 38.4 },
  EB: { mobileSize: 12, deskTopSize: 12 },
  JW: { mobileSize: 32, deskTopSize: 32 },
  AK: { mobileSize: 32, deskTopSize: 32 },
  BR: { mobileSize: 17.28, deskTopSize: 17.28 },
  GE: { mobileSize: 32, deskTopSize: 32 },
  CY: { mobileSize: 8.32, deskTopSize: 8.32 },
  RI: { mobileSize: 9.28, deskTopSize: 9.28 },
  SH: { mobileSize: 20.48, deskTopSize: 20.48 },
  FI: { mobileSize: 10.72, deskTopSize: 10.72 },
  TS: { mobileSize: 15.2, deskTopSize: 15.2 },
  PR: { mobileSize: 20.32, deskTopSize: 20.32 },
  OX: { mobileSize: 22.4, deskTopSize: 22.4 },
  LC: { mobileSize: 32, deskTopSize: 32 },
  WI: { mobileSize: 9.6, deskTopSize: 9.6 },
  SI: { mobileSize: 32, deskTopSize: 32 },
  MD: { mobileSize: 9.6, deskTopSize: 9.6 },
  TX: { mobileSize: 12.8, deskTopSize: 16 },
  DS: { mobileSize: 17.6, deskTopSize: 17.6 },
  AL: { mobileSize: 20.8, deskTopSize: 20.8 },
  HV: { mobileSize: 32, deskTopSize: 32 },
  MV: { mobileSize: 32, deskTopSize: 45 },
  RCR: { mobileSize: 10.3, deskTopSize: 10.5 },
  BG: { mobileSize: 7, deskTopSize: 7 },
  trc: { mobileSize: 15, deskTopSize: 17 },
  reserve: { mobileSize: 4.8, deskTopSize: 4.8 },
  MG: { mobileSize: 16, deskTopSize: 24 },
  WV: { mobileSize: 16, deskTopSize: 24 },
  SV: { mobileSize: 16, deskTopSize: 24 },
  BA: { mobileSize: 16, deskTopSize: 24 },
  WH_word_mark: { mobileSize: 7, deskTopSize: 8 },
  DEFAULT: { mobileSize: 32, deskTopSize: 32 },
};

export enum ScrollDirection {
  UP = 'up',
  DOWN = 'down',
  INDETERMINATE = '',
}

export interface KeyEvent {
  key: string;
  keyCode: number;
}

export const KEY_BOARD_EVENTS: Record<string, KeyEvent> = {
  ENTER: { key: 'Enter', keyCode: 13 },
  TAB: { key: 'Tab', keyCode: 9 },
  ESCAPE: { key: 'Escape', keyCode: 27 },
  ARROW_UP: { key: 'ArrowUp', keyCode: 38 },
  ARROW_DOWN: { key: 'ArrowDown', keyCode: 40 },
  ARROW_RIGHT: { key: 'ArrowRight', keyCode: 39 },
  ARROW_LEFT: { key: 'ArrowLeft', keyCode: 37 },
  SPACEBAR: { key: ' ', keyCode: 32 },
};

export enum ImageAspectRatio {
  SIZE_16x9 = '16:9',
  SIZE_5x2 = '5:2',
  SIZE_3x1 = '3:1',
  SIZE_2X1 = '2:1',
}

// Use This when we want brand logo in 90*60 size
export const FONT_ICON_CARD_LAYRED_LOGO_SIZE: Record<string, FontIconSize> = {
  RZ: { mobileSize: 40, deskTopSize: 40 },
  EB: { mobileSize: 16, deskTopSize: 16 },
  JW: { mobileSize: 35, deskTopSize: 35 },
  AK: { mobileSize: 37, deskTopSize: 37 },
  BR: { mobileSize: 46, deskTopSize: 46 },
  GE: { mobileSize: 43, deskTopSize: 43 },
  CY: { mobileSize: 14, deskTopSize: 14 },
  RI: { mobileSize: 16, deskTopSize: 16 },
  SH: { mobileSize: 33, deskTopSize: 33 },
  FI: { mobileSize: 25, deskTopSize: 25 },
  TS: { mobileSize: 30, deskTopSize: 30 },
  PR: { mobileSize: 39, deskTopSize: 39 },
  OX: { mobileSize: 32, deskTopSize: 32 },
  LC: { mobileSize: 42, deskTopSize: 42 },
  WI: { mobileSize: 20, deskTopSize: 20 },
  SI: { mobileSize: 42, deskTopSize: 42 },
  MD: { mobileSize: 13, deskTopSize: 13 },
  TX: { mobileSize: 18, deskTopSize: 18 },
  DS: { mobileSize: 9.3, deskTopSize: 9.3 },
  AL: { mobileSize: 38, deskTopSize: 38 },
  HV: { mobileSize: 38, deskTopSize: 38 },
  MV: { mobileSize: 45, deskTopSize: 45 },
  RCR: { mobileSize: 20, deskTopSize: 20 },
  BG: { mobileSize: 20.8, deskTopSize: 20.8 },
  trc: { mobileSize: 17, deskTopSize: 17 },
  reserve: { mobileSize: 9, deskTopSize: 9 },
  MG: { mobileSize: 27, deskTopSize: 27 },
  WV: { mobileSize: 30, deskTopSize: 30 },
  SV: { mobileSize: 46, deskTopSize: 46 },
  BA: { mobileSize: 47, deskTopSize: 47 },
  XR: { mobileSize: 44, deskTopSize: 44 },
  WH: { mobileSize: 16, deskTopSize: 16 },
  DE: { mobileSize: 48, deskTopSize: 48 },
  MC: { mobileSize: 44, deskTopSize: 44 },
  AR: { mobileSize: 44, deskTopSize: 44 },
  EL: { mobileSize: 48, deskTopSize: 48 },
  RS: { mobileSize: 30, deskTopSize: 30 },
  XE: { mobileSize: 40, deskTopSize: 40 },
  XF: { mobileSize: 40, deskTopSize: 40 },
  SN: { mobileSize: 70, deskTopSize: 70 },
  DEFAULT: { mobileSize: 32, deskTopSize: 32 },
};

export const FONT_ICON_BRAND_LOGO_SIZE_D135_M90: Record<string, FontIconSize> = {
  RZ: { mobileSize: 36.32, deskTopSize: 54.51 },
  EB: { mobileSize: 16.54, deskTopSize: 24.81 },
  JW: { mobileSize: 33.45, deskTopSize: 53.2 },
  AK: { mobileSize: 37.53, deskTopSize: 56.3 },
  BR: { mobileSize: 47.18, deskTopSize: 70.77 },
  GE: { mobileSize: 43.68, deskTopSize: 65.52 },
  CY: { mobileSize: 13.92, deskTopSize: 20.88 },
  RI: { mobileSize: 16.18, deskTopSize: 24.27 },
  SH: { mobileSize: 33.79, deskTopSize: 50.68 },
  FI: { mobileSize: 25.34, deskTopSize: 38.01 },
  TS: { mobileSize: 30.14, deskTopSize: 45.21 },
  PR: { mobileSize: 39.26, deskTopSize: 58.89 },
  OX: { mobileSize: 30.52, deskTopSize: 45.79 },
  LC: { mobileSize: 49.15, deskTopSize: 73.72 },
  WI: { mobileSize: 20.06, deskTopSize: 30.09 },
  SI: { mobileSize: 42.24, deskTopSize: 63.36 },
  MD: { mobileSize: 13.24, deskTopSize: 19.87 },
  TX: { mobileSize: 18.62, deskTopSize: 27.93 },
  DS: { mobileSize: 9.3, deskTopSize: 13.97 },
  AL: { mobileSize: 42.04, deskTopSize: 63.07 },
  HV: { mobileSize: 38.97, deskTopSize: 58.464 },
  WV: { mobileSize: 30.46, deskTopSize: 45.7 },
  MG: { mobileSize: 27.61, deskTopSize: 41.42 },
  WH: { mobileSize: 12.4, deskTopSize: 18.6 },
  RCR: { mobileSize: 14.42, deskTopSize: 21.6 },
  BA: { mobileSize: 47.12, deskTopSize: 70.68 },
  BG: { mobileSize: 20.8, deskTopSize: 31.2 },
  XE: { mobileSize: 28.8, deskTopSize: 41.6 },
  XF: { mobileSize: 30.4, deskTopSize: 44.8 },
  MC: { mobileSize: 66.56, deskTopSize: 99.84 },
  FP: { mobileSize: 64, deskTopSize: 64 },
  DE: { mobileSize: 64, deskTopSize: 64 },
  AR: { mobileSize: 64, deskTopSize: 64 },
  XR: { mobileSize: 64, deskTopSize: 64 },
  EL: { mobileSize: 64, deskTopSize: 64 },
  MV: { mobileSize: 64, deskTopSize: 64 },
  ER: { mobileSize: 43.36, deskTopSize: 65.04 },
  SV: { mobileSize: 47.63, deskTopSize: 71.45 },
  RS: { mobileSize: 20.8, deskTopSize: 30.4 },
  SN: { mobileSize: 60.8, deskTopSize: 88 },
  ESCAPE: { mobileSize: 50.6, deskTopSize: 76 },
  DEFAULT: { mobileSize: 32, deskTopSize: 32 },
};

export const FONT_ICON_PORTFOLIO_LOGO_SIZE_D135_M90 = {
  'ritz-carlton': { mobileSize: 36.32, deskTopSize: 54.51 },
  edition: { mobileSize: 16.54, deskTopSize: 24.81 },
  jw: { mobileSize: 33.45, deskTopSize: 53.2 },
  'autograph-collection': { mobileSize: 47.18, deskTopSize: 70.77 },
  renaissance: { mobileSize: 47.18, deskTopSize: 70.77 },
  gaylord: { mobileSize: 43.68, deskTopSize: 65.52 },
  courtyard: { mobileSize: 13.92, deskTopSize: 20.88 },
  'residence-inn': { mobileSize: 16.18, deskTopSize: 24.27 },
  'springhill-suites': { mobileSize: 33.79, deskTopSize: 50.68 },
  'fairfield-inn': { mobileSize: 25.34, deskTopSize: 38.01 },
  'towneplace-suites': { mobileSize: 30.14, deskTopSize: 45.21 },
  'protea-hotels': { mobileSize: 39.26, deskTopSize: 58.89 },
  moxy: { mobileSize: 30.52, deskTopSize: 45.79 },
  'luxury-collection': { mobileSize: 49.15, deskTopSize: 73.72 },
  westin: { mobileSize: 20.06, deskTopSize: 30.09 },
  sheraton: { mobileSize: 42.24, deskTopSize: 63.36 },
  'le-meridien': { mobileSize: 13.24, deskTopSize: 19.87 },
  'tribute-portfolio': { mobileSize: 13.24, deskTopSize: 19.87 },
  'design-hotels': { mobileSize: 9.3, deskTopSize: 13.97 },
  aloft: { mobileSize: 42.04, deskTopSize: 63.07 },
  hvmi: { mobileSize: 38.97, deskTopSize: 58.464 },
  'westin-vacation-club': { mobileSize: 30.46, deskTopSize: 45.7 },
  mg: { mobileSize: 27.61, deskTopSize: 41.42 },
  'w-hotels': { mobileSize: 12.4, deskTopSize: 18.6 },
  rcr: { mobileSize: 14.42, deskTopSize: 21.6 },
  ba: { mobileSize: 14.42, deskTopSize: 21.6 },
  marriott: { mobileSize: 66.56, deskTopSize: 99.84 },
  'four-points': { mobileSize: 64, deskTopSize: 64 },
  'delta-hotels': { mobileSize: 64, deskTopSize: 64 },
  'ac-hotels': { mobileSize: 64, deskTopSize: 64 },
  'st-regis': { mobileSize: 64, deskTopSize: 64 },
  element: { mobileSize: 64, deskTopSize: 64 },
  mvc: { mobileSize: 64, deskTopSize: 64 },
  'marriott-executive-apartments': { mobileSize: 43.36, deskTopSize: 65.04 },
  'sheraton-vacation-club': { mobileSize: 47.63, deskTopSize: 71.45 },
  xe: { mobileSize: 28.8, deskTopSize: 41.6 },
  xf: { mobileSize: 30.4, deskTopSize: 44.8 },
  rs: { mobileSize: 20.8, deskTopSize: 30.4 },
  escape: { mobileSize: 50.6, deskTopSize: 76 },
  DEFAULT: { mobileSize: 32, deskTopSize: 32 },
};

export const expanded_constants = {
  SEE_MORE: 'See More',
  SEE_LESS: 'See Less',
};

export enum HeaderTextStyle {
  WITHOUT_CO_BRAND = 'withoutCoBrand',
  WITH_CO_BRAND = 'withCoBrand',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export enum Target {
  ENABLE_TARGET = 'enable-target',
  DISABLE_TARGET = '',
}

export enum AssetVariation {
  FONT_ICON = 'fonticon',
  IMAGE = 'image',
  VERTICAL_IMAGE = 'verticalImage',
}

export enum HeaderCopyWidth {
  DEFAULT = 'default',
  EXTRATHIN = 'extraThin',
  THIN = 'thin',
  WIDE = 'wide',
}

export enum TOOLTIP_POSITION {
  top = 'top',
  right = 'right',
  default = '',
  bottom = 'bottom',
}
