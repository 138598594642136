import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledSignInDrawer = styled.div`
  [role='dialog'] {
    height: 100vh;
    overflow-y: scroll;
  }
`;

export const StyledSignInButton = styled.button`
  display: flex;
  align-items: center;
  && {
    background: transparent;
    padding: 0;
    color: inherit;
    &:focus {
      outline: 1px dotted #212121 !important;
      outline: 5px auto -webkit-focus-ring-color !important;
    }

    @media ${baseVariables.mediaQuery.lg} {
      padding: ${toRem(8)} ${toRem(16)};
      border: ${toRem(1)} solid;
      border-radius: ${toRem(20)};
      margin: 0 0 0 ${toRem(16)};
    }

    &.rememberedBtn {
      @media ${baseVariables.mediaQuery.xl} {
        border: none;
      }
    }
    .white-header & {
      color: ${baseVariables.color['base10']};
      @media ${baseVariables.mediaQuery.lg} {
        border-color: ${baseVariables.color['base10']};
      }
    }
    .icon-arrow-right {
      margin-left: ${toRem(4)};
    }
  }
`;

export const AccountDrawer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  width: 100vw;
  z-index: ${({ isOpen }) => (isOpen ? `999` : `-1`)};
  background: ${({ isOpen }) => (isOpen ? `rgba(28, 28, 28, 0.56)` : `rgba(28, 28, 28, 0)`)};
  transition: background-color 0.3s;
`;
